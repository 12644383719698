import React, {useContext, useReducer, useState} from 'react';
import style from '../Diary/DiaryStyle.module.css';
import {AuthContext} from "../../Providers/AuthProvider";
import {DayPanel, DiaryCarousel, getNormalWeekDay, StudentDiary} from "../Diary/StudentDiary";
import {getDate, getStringTime} from "./StudentCalendar";
import deleteIcon from '../../Images/deleteIcon.png';
import clearIcon from '../../Images/clearIcon.png';
import copyIcon from '../../Images/copyIcon.png';
import pasteIcon from '../../Images/pasteIcon.png';
import cancelChangesIcon from '../../Images/cancelChangesIcon.png';
import saveIcon from '../../Images/saveIcon.png';
import userIcon from '../../Images/userIcon.png';
import resetIcon from '../../Images/resetIcon.png';
import {ApiInstance, baseUrl} from "../../api";
import Swal from "sweetalert2";
import {GroupContext} from "../../Providers/GroupProvider";
import {Navigate} from "react-router-dom";
import {FormRedirectBlocker, groupBy} from "../Journal/Journal";

const DAYS = [
    'Понедельник',
    'Вторник',
    'Среда',
    'Четверг',
    'Пятница',
    'Суббота',
    'Воскресенье',
];
const MONTHS = [
    "янв.",
    "февр.",
    "март",
    "апр.",
    "май",
    "июнь",
    "июль",
    "авг.",
    "сент.",
    "окт.",
    "ноябрь",
    "дек.",
];

TeacherSchedule.cache = {};
TeacherSchedule.updated = {};
TeacherSchedule.added = {};
TeacherSchedule.subjects = [];
TeacherSchedule.holidays = {};
TeacherSchedule.holidaysUpdate = {};

TeacherSchedule.buffer = null;

TeacherSchedule.cacheClear = function () {
    TeacherSchedule.cache = {};
    TeacherSchedule.updated = {};
    TeacherSchedule.added = {};
    TeacherSchedule.subjects = [];
    TeacherSchedule.buffer = null;
}

export function TeacherSchedule({firstWeekDay}) {
    const {token} = useContext(AuthContext);
    const [group] = useContext(GroupContext);
    const [week, setWeek] = useState(null);
    const [subjects, setSubjects] = useState(null);
    const [holidays, setHolidays] = useState(null);
    const [loading, setLoading] = useState(false);
    const [hasChanges, setChanges] = useState(false);
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    FormRedirectBlocker(hasChanges);

    if (!token) return <Navigate to="/auth" replace={true}/>;
    if (!group) return;
    if (subjects == null) loadSubjects();
    const firstWeekDayDate = StudentDiary.getWeekRange(new Date(Date.parse(firstWeekDay))).startDay;
    const lastWeekDate = group.period.range[1] ? StudentDiary.getWeekRange(new Date(Date.parse(group.period.range[1]))).startDay : null;
    const lastWeek = lastWeekDate ? StudentDiary.weeksDifference(lastWeekDate, firstWeekDayDate) : null;

    if (week == null) {
        const nominal = StudentDiary.weeksDifference(getDate(new Date()), firstWeekDayDate);
        const diff = lastWeek != null && lastWeek > 0 ? Math.min(lastWeek, nominal) : 0;
        setWeek(diff);
        loadWeek(diff);
        return "";
    }
    if (subjects == null) return "";

    function loadWeek(week) {
        if (TeacherSchedule.cache[week] != null) return;
        loadHolidays(week);
        let cd = new Date(firstWeekDayDate);
        const dateDiff = firstWeekDayDate.getDate() + week * 7;
        cd.setDate(dateDiff);
        const normal = getDate(cd).toISOString().split('T')[0];
        setLoading(true);
        ApiInstance(token).get('/schedule?day=' + normal).then(r => {
            let data = r.data;
            data.forEach(item => {
                const startAt = new Date(item.startAt);
                item.day = getNormalWeekDay(startAt.getDay());
                item.title = item.subject.name;
            });
            data.sort((a, b) => new Date(a.startAt) - new Date(b.startAt));
            data = groupBy(data, (item) => item.day);
            TeacherSchedule.cache[week] = data;

            setLoading(false);
        });
    }

    function loadHolidays(week) {
        if (TeacherSchedule.holidays[week]) return;
        TeacherSchedule.holidays[week] = {};
        let cd = new Date(firstWeekDayDate);
        const dateDiff = firstWeekDayDate.getDate() + week * 7;
        cd.setDate(dateDiff);
        const normal = getDate(cd).toISOString().split('T')[0];
        ApiInstance(token).get(`/holiday/week/${group.periodId}?day=${normal}`).then(r => {
            let data = r.data;
            data.forEach(item => {
                const startAt = new Date(item.day);
                item.dayIndex = getNormalWeekDay(startAt.getDay());
            });
            data.sort((a, b) => new Date(a.day) - new Date(b.day));
            data = groupBy(data, (item) => item.dayIndex);
            Object.keys(data).forEach(day => data[day] = data[day][0]);
            TeacherSchedule.holidays[week] = data;
            setHolidays(true);
        });
    }

    function loadSubjects() {
        if (subjects != null) return;
        ApiInstance(token).get(`/periods/${group.periodId}/subjects`).then(r => {
            TeacherSchedule.subjects = r.data;
            setSubjects(true);
        });
    }

    function updateWeek(week) {
        setWeek(week);
        loadWeek(week);
    }

    let days = [];
    const dateDiff = firstWeekDayDate.getDate() + week * 7;
    for (let i = 0; i < 6; i++) {
        let cd = new Date(firstWeekDayDate);
        cd.setDate(dateDiff + i);
        cd.setHours(23);
        cd.setMinutes(59);
        cd.setSeconds(59);
        days.push({
            name: DAYS[i],
            dateInstance: cd,
            date: String(cd.getDate()).padStart(2, "0") + " " + MONTHS[cd.getMonth()] + " " + String(cd.getFullYear()).padStart(2, "0"),
            dateIso: String(cd.getFullYear()).padStart(2, "0") + "-" + String(cd.getMonth() + 1).padStart(2, "0") + "-" + String(cd.getDate()).padStart(2, "0"),
            status: cd.getTime() > Date.now()
        })
    }

    function copy() {
        const updatedData = TeacherSchedule.updated[week] ?? {};
        const addedData = TeacherSchedule.added[week] ?? {};
        let existingData = JSON.parse(JSON.stringify(TeacherSchedule.cache[week] ?? {}));
        Object.keys(existingData).forEach(day => {
            if (!updatedData[day]) return;
            const dayData = existingData[day];
            dayData.forEach((lesson, i) => existingData[day][i] = updatedData[day][i] ?? existingData[day][i]);
        });
        Object.keys(addedData).forEach(day => {
            const dayData = addedData[day];
            dayData.forEach(added => {
                if (!existingData[day]) existingData[day] = [];
                existingData[day].push(added);
            });
        });
        TeacherSchedule.buffer = existingData;
        Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000
        }).fire({
            icon: "success",
            title: "Неделя скопирована"
        });
    }

    function clearWeek() {
        days.forEach((day, i) => {
            if (day.status) {
                if (!TeacherSchedule.cache[week]) return;
                if (!TeacherSchedule.cache[week][i]) return;
                Object.keys(TeacherSchedule.cache[week][i]).forEach((lesson, index) => {
                    if (lesson.startAt && (new Date(lesson.startAt)).getTime() < Date.now()) return;
                    if (!TeacherSchedule.updated[week]) TeacherSchedule.updated[week] = {};
                    else if (TeacherSchedule.updated[week][i] && TeacherSchedule.updated[week][i][index]) {
                        const start = TeacherSchedule.updated[week][i][index].startAt;
                        if ((new Date(start)).getTime() < Date.now()) return;
                    }
                    if (!TeacherSchedule.updated[week][i]) TeacherSchedule.updated[week][i] = {};
                    TeacherSchedule.updated[week][i][index] = null;
                });
            }
        });
    }

    function paste() {
        if (TeacherSchedule.buffer == null) {
            Swal.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000
            }).fire({
                icon: "error",
                title: "Нет скопированных данных"
            });
            return;
        }

        clearWeek();

        Object.keys(TeacherSchedule.buffer).forEach(i => {
            if (!days[i].status) return;
            TeacherSchedule.buffer[i].forEach(lesson => {
                if (!TeacherSchedule.added[week]) TeacherSchedule.added[week] = {};
                if (!TeacherSchedule.added[week][i]) TeacherSchedule.added[week][i] = [];
                let startTime = lesson.startAt ? lesson.startAt.split(" ")[1] : null;
                let finishTime = lesson.finishAt ? lesson.finishAt.split(" ")[1] : null;
                if (startTime) startTime = `${days[i].dateIso} ${startTime}`;
                if (finishTime) finishTime = `${days[i].dateIso} ${finishTime}`;
                if (startTime) {
                    if ((new Date(startTime)).getTime() < Date.now()) return;
                }
                TeacherSchedule.added[week][i].push({
                    startAt: startTime,
                    finishAt: finishTime,
                    subjectId: lesson.subjectId ?? null
                });
            });
        });

        dayUpdated();
        forceUpdate();
        updateWeek(week);

        Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 2000
        }).fire({
            icon: "success",
            title: "Данные применены"
        });
    }

    function clear() {
        Swal.fire({
            title: `Подтвердите что хотите очистить запланированные дни за ${week + 1} неделю`,
            showCancelButton: true,
            confirmButtonText: 'Подтвердить',
            cancelButtonText: 'Отменить',
            confirmButtonColor: "rgb(192, 217, 165)",
            customClass: {
                confirmButton: style.modalButton,
                cancelButton: style.modalButton,
            },
        }).then(result => {
            if (result.isConfirmed) {
                clearWeek();
                if (TeacherSchedule.added[week]) delete TeacherSchedule.added[week];
                dayUpdated();
                forceUpdate();
                updateWeek(week);
                Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 2000
                }).fire({
                    icon: "success",
                    title: "Расписание за неделю удалено"
                });
            }
        });
    }

    function uploadDeleting() {
        return new Promise((resolve, reject) => {
            let deleting = [];
            Object.keys(TeacherSchedule.updated).forEach(week => {
                Object.keys(TeacherSchedule.updated[week]).forEach(day => {
                    Object.keys(TeacherSchedule.updated[week][day]).forEach(lesson => {
                        if (TeacherSchedule.updated[week][day][lesson] == null) {
                            deleting.push(TeacherSchedule.cache[week][day][lesson].id);
                        }
                    });
                });
            });
            ApiInstance(token).delete("/schedule/delete/all", {data: deleting})
                .then(() => {
                    Object.keys(TeacherSchedule.updated).forEach(week => {
                        Object.keys(TeacherSchedule.updated[week]).forEach(day => {
                            Object.keys(TeacherSchedule.updated[week][day]).forEach(lesson => {
                                if (TeacherSchedule.updated[week][day][lesson] == null) {
                                    delete TeacherSchedule.updated[week][day][lesson];
                                    TeacherSchedule.cache[week][day][lesson].removed = true;
                                }
                            });
                        });
                    });
                    resolve(true);
                })
                .catch(r => reject(r.response));
        });
    }

    function uploadUpdating() {
        return new Promise((resolve, reject) => {
            let updating = [];
            Object.keys(TeacherSchedule.updated).forEach(week => {
                Object.keys(TeacherSchedule.updated[week]).forEach(day => {
                    Object.keys(TeacherSchedule.updated[week][day]).forEach(lesson => {
                        if (TeacherSchedule.updated[week][day][lesson]) {
                            let data = TeacherSchedule.updated[week][day][lesson];
                            if (data.fileRemove) data.file = "null";
                            else if (!data.file) delete data.file;
                            updating.push(data);
                        }
                    });
                });
            });
            ApiInstance(token).post(`/schedule/edit/all`, updating, {headers: {"Content-Type": "multipart/form-data"}})
                .then(() => resolve(true))
                .catch(r => reject(r.response));
        });
    }

    function uploadCreating() {
        return new Promise((resolve, reject) => {
            let creating = [];
            Object.keys(TeacherSchedule.added).forEach(week => {
                Object.keys(TeacherSchedule.added[week]).forEach(day => {
                    TeacherSchedule.added[week][day].forEach(lesson => {
                        creating.push(lesson);
                    });
                });
            });
            ApiInstance(token).post("/schedule/create/all", creating, {headers: {"Content-Type": "multipart/form-data"}})
                .then(() => resolve(true))
                .catch(r => reject(r.response));
        });
    }

    function uploadHolidays() {
        return new Promise((resolve, reject) => {
            let creating = [];
            let deleting = [];
            Object.keys(TeacherSchedule.holidaysUpdate).forEach(week => {
                const dateDiff = firstWeekDayDate.getDate() + week * 7;
                Object.keys(TeacherSchedule.holidaysUpdate[week]).forEach(day => {
                    const value = TeacherSchedule.holidaysUpdate[week][day];
                    if (!value) {
                        deleting.push(TeacherSchedule.holidays[week][day].id);
                    } else {
                        let cd = new Date(firstWeekDayDate);
                        cd.setDate(dateDiff + parseInt(day));
                        cd.setHours(23);
                        cd.setMinutes(59);
                        cd.setSeconds(59);
                        let dateIso = String(cd.getFullYear()).padStart(2, "0") + "-" + String(cd.getMonth() + 1).padStart(2, "0") + "-" + String(cd.getDate()).padStart(2, "0");
                        creating.push({day: dateIso, periodId: group.periodId});
                    }
                });
            });
            ApiInstance(token).delete("/schedule/holiday/delete", {
                data: deleting,
            })
                .then(() => {
                    ApiInstance(token).post("/schedule/holiday/create", creating, {
                        headers: {"Content-Type": "multipart/form-data"}
                    })
                        .then(() => {
                            resolve(true);
                            TeacherSchedule.holidaysUpdate = {};
                            TeacherSchedule.holidays = {};
                        })
                        .catch(r => reject(r.response));
                })
                .catch(r => reject(r.response));
        });
    }

    function validationError(r) {
        if (r.status === 422) {
            if (r.data.errors) {
                let string = "";
                Object.keys(r.data.errors).forEach(key => {
                    string = string + "\n" + r.data.errors[key][0];
                });
                Swal.fire({
                    title: "Ошибка запроса, данные неверные",
                    text: string,
                    icon: "error"
                });
            }
        } else {
            Swal.fire({
                title: "Ошибка запроса",
                text: "Код - " + r.status + "\n" + r.statusText,
                icon: "error"
            });
        }
    }

    async function save() {
        uploadDeleting().then(() => {
            uploadUpdating().then(() => {
                uploadCreating().then(() => {
                    uploadHolidays().then(() => {
                        Swal.mixin({
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 2000
                        }).fire({
                            icon: "success",
                            title: "Данные сохранены"
                        });
                        TeacherSchedule.cache = {};
                        TeacherSchedule.updated = {};
                        TeacherSchedule.added = {};
                        setChanges(false);
                        updateWeek(week);
                    });
                }).catch(r => {
                    validationError(r);
                    forceUpdate();
                });
            }).catch(r => {
                validationError(r);
                forceUpdate();
            });
        });
    }

    function cancel() {
        Swal.fire({
            title: `Подтвердите что хотите отменить изменения`,
            showCancelButton: true,
            confirmButtonText: 'Подтвердить',
            cancelButtonText: 'Отменить',
            confirmButtonColor: "rgb(192, 217, 165)",
            customClass: {
                confirmButton: style.modalButton,
                cancelButton: style.modalButton,
            },
        }).then(result => {
            if (result.isConfirmed) {
                TeacherSchedule.updated = {};
                TeacherSchedule.added = {};
                TeacherSchedule.holidaysUpdate = {};
                setChanges(false);
            }
        });
    }

    function dayUpdated() {
        const has = Object.keys(TeacherSchedule.updated).length > 0 || Object.keys(TeacherSchedule.added).length > 0 || Object.keys(TeacherSchedule.holidaysUpdate).length > 0;
        if (has !== hasChanges) setChanges(has);
    }

    return (
        <div>
            <DiaryCarousel
                week={week}
                lastWeek={Math.max(0, lastWeek)}
                setWeek={updateWeek}
                days={days}/>
            {loading ? <div style={{display: "flex", justifyContent: "center"}}>
                <div className={style.loader}></div>
            </div> : ""}
            {days.map((day, index) =>
                <Day
                    key={"day" + index}
                    dayData={day}
                    week={week}
                    day={index}
                    update={dayUpdated}
                />
            )}
            <CrudActions editable={days[5].status} copy={copy} paste={paste} clear={clear}/>
            <SaveActions hasChanges={hasChanges} save={save} cancel={cancel}/>
        </div>
    );
}

export function SaveActions({hasChanges, save, cancel}) {
    if (!hasChanges) return "";
    return (<div style={{display: "flex"}}>
        <button className={style.button} style={{background: "#C0D9A5"}} onClick={save}>
            <img src={"" + saveIcon} alt=""/>
            <div>Сохранить изменения</div>
        </button>
        <button disabled={!hasChanges} className={style.button} onClick={cancel}>
            <img src={"" + cancelChangesIcon} alt=""/>
            <div>Отменить</div>
        </button>
    </div>);
}

function CrudAction({icon, name, description, action}) {
    return (<button className={style.crudActionButton} onClick={action}>
        <img src={"" + icon} alt=""/>
        <div><p>{name}</p><small>{description}</small></div>
    </button>);
}

function CrudActions({copy, paste, clear, editable}) {
    return (<div style={{display: "flex", margin: "40px 0"}}>
        <CrudAction icon={copyIcon} name="Скопировать расписание"
                    description="Только время и предметы/мероприятия, в соответствии с дням недели." action={copy}/>
        {editable ? <CrudAction icon={pasteIcon} name="Вставить/заменить расписание"
                                description="Информация вставляется по дням. Завершенные дни не могут быть изменены."
                                action={paste}/> : ""}
        {editable ? <CrudAction icon={clearIcon} name="Очистить расписание"
                                description="Информация по завершенным дням не будет удалена." action={clear}/> : ""}
    </div>);
}

function Day({dayData, week, day, update}) {
    const [show, setShow] = useState(false);
    const data = TeacherSchedule.cache[week] ? TeacherSchedule.cache[week][day] ?? [] : [];
    const updatedData = TeacherSchedule.updated[week] ? TeacherSchedule.updated[week][day] ?? {} : {};
    const addedData = TeacherSchedule.added[week] ? TeacherSchedule.added[week][day] ?? [] : [];
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    function collapseExistingLessonUpdatedData() {
        if (Object.keys(TeacherSchedule.updated[week][day]).length !== 0) return;
        delete TeacherSchedule.updated[week][day];
        if (Object.keys(TeacherSchedule.updated[week]).length !== 0) return;
        delete TeacherSchedule.updated[week];
    }

    function collapseAddedLessonData() {
        if (Object.keys(TeacherSchedule.added[week]).length !== 0) return;
        delete TeacherSchedule.added[week];
    }

    function updateExistingLesson(i, data) {
        const {changed, schedule} = data;
        if (!TeacherSchedule.updated[week]) TeacherSchedule.updated[week] = {};
        if (!TeacherSchedule.updated[week][day]) TeacherSchedule.updated[week][day] = {};
        if (!TeacherSchedule.updated[week][day][i]) TeacherSchedule.updated[week][day][i] = {};
        if (changed) {
            TeacherSchedule.updated[week][day][i] = schedule;
        } else {
            delete TeacherSchedule.updated[week][day][i];
            collapseExistingLessonUpdatedData();
        }
        update();
    }

    function updateAddedLesson(i, data) {
        const {schedule} = data;
        TeacherSchedule.added[week][day][i] = schedule;
        update();
    }

    function removeExistingLesson(i) {
        if (!TeacherSchedule.updated[week]) TeacherSchedule.updated[week] = {};
        if (!TeacherSchedule.updated[week][day]) TeacherSchedule.updated[week][day] = {};
        TeacherSchedule.updated[week][day][i] = null;
        forceUpdate();
        update();
    }

    function restoreExistingLesson(i) {
        delete TeacherSchedule.updated[week][day][i];
        collapseExistingLessonUpdatedData();
        forceUpdate();
        update();
    }

    function removeAddedLesson(i) {
        TeacherSchedule.added[week][day].splice(i, 1);
        if (TeacherSchedule.added[week][day].length === 0) {
            delete TeacherSchedule.added[week][day];
            collapseAddedLessonData();
        }
        forceUpdate();
        update();
    }

    function addRow() {
        if (!TeacherSchedule.added[week]) TeacherSchedule.added[week] = {};
        if (!TeacherSchedule.added[week][day]) TeacherSchedule.added[week][day] = [];
        TeacherSchedule.added[week][day].push({
            "subjectId": null,
            "theme": null,
            "homeTask": null,
            "comment": null
        });
        forceUpdate();
        update();
    }

    function getExistingLessonData(index) {
        if (updatedData[index] === undefined) return data[index];
        return TeacherSchedule.updated[week][day][index];
    }

    function updateHoliday(e) {
        const value = e.target.checked;
        forceUpdate();
        if (!value) {
            if (TeacherSchedule.holidays[week] && TeacherSchedule.holidays[week][day]) {
                if (!TeacherSchedule.holidaysUpdate[week]) TeacherSchedule.holidaysUpdate[week] = {};
                TeacherSchedule.holidaysUpdate[week][day] = false;
            } else {
                delete TeacherSchedule.holidaysUpdate[week][day];
                if (Object.keys(TeacherSchedule.holidaysUpdate[week]).length === 0) {
                    delete TeacherSchedule.holidaysUpdate[week];
                }
            }
        } else {
            if (TeacherSchedule.holidays[week] && TeacherSchedule.holidays[week][day]) {
                delete TeacherSchedule.holidaysUpdate[week][day];
                if (Object.keys(TeacherSchedule.holidaysUpdate[week]).length === 0) {
                    delete TeacherSchedule.holidaysUpdate[week];
                }
            } else {
                if (!TeacherSchedule.holidaysUpdate[week]) TeacherSchedule.holidaysUpdate[week] = {};
                TeacherSchedule.holidaysUpdate[week][day] = true;
            }
        }
        update();
    }

    function isHoliday() {
        if (!TeacherSchedule.holidaysUpdate[week] || TeacherSchedule.holidaysUpdate[week][day] == null) {
            return (TeacherSchedule.holidays[week] && TeacherSchedule.holidays[week][day]) ?? false;
        }
        return TeacherSchedule.holidaysUpdate[week][day];
    }

    let offset = 0;

    return (<div ref={dayData.ref}>
        <DayPanel day={dayData} show={show} setShow={setShow}/>
        {dayData.status ? <div>
            <input style={{margin: "0.2rem"}}
                   checked={isHoliday()}
                   type="checkbox"
                   onChange={updateHoliday}/>
            <span>каникулы</span>
        </div> : ""}
        <div className={style.schedule} style={{display: show || dayData.status ? "flex" : "none"}}>
            <table className={!dayData.status ? style.status : {}}>
                <thead>
                <tr>
                    <th style={{width: "6%"}}>№</th>
                    <th style={{width: "10%"}} className={style.time}>Время</th>
                    <th style={{width: "15%"}}>Предмет/мероприятие</th>
                    <th style={{width: "20%"}}>Тема</th>
                    <th style={{width: "20%"}}>Домашнее задание</th>
                    <th style={{width: "20%"}}>Комментарий</th>
                    <th style={{width: "9%"}}>Действия</th>
                </tr>
                </thead>
                <tbody>
                {data.length > 0 ?
                    data.map((lesson, i) => {
                        const data = getExistingLessonData(i);
                        const disabled = (data == null);
                        offset += disabled;
                        return (
                            <ScheduleItem
                                editable={!isHoliday()}
                                key={"schedule_" + lesson.id}
                                dayStatus={dayData.status}
                                disabled={disabled}
                                date={dayData.dateIso}
                                existing={true}
                                update={(data) => updateExistingLesson(i, data)}
                                index={i - offset}
                                removeRow={() => removeExistingLesson(i)}
                                restoreRow={() => restoreExistingLesson(i)}
                                schedule={data ?? lesson}
                            />);
                    })
                    :
                    (dayData.status ? "" : <tr>
                        <td colSpan="7">Нет занятий</td>
                    </tr>)
                }
                {
                    addedData.map((lesson, i) =>
                        <ScheduleItem
                            key={"schedule_" + week + "_" + day + "_" + i}
                            dayStatus={dayData.status}
                            date={dayData.dateIso}
                            existing={false}
                            update={(data) => updateAddedLesson(i, data)}
                            removeRow={() => removeAddedLesson(i)}
                            index={data.length + i - offset}
                            schedule={lesson}
                        />
                    )
                }
                {dayData.status ?
                    isHoliday() ? <tr>
                            <td colSpan="7" style={{padding: 0, overflow: "auto"}}>
                                Каникулы
                            </td>
                        </tr> :
                        <tr>
                            <td colSpan="7" style={{padding: 0, overflow: "auto"}}>
                                <button onClick={addRow} className={style.addRowButton}>+ Добавить строку
                                </button>
                            </td>
                        </tr> : ""}
                </tbody>
            </table>
        </div>
    </div>);
}

class ScheduleItem extends React.Component {
    dayStatus = false;
    index = 0;
    schedule = {};
    data = {};
    disabled = false;
    date = "";
    existing = true;
    editable = true;
    removeRow = () => {
    };
    restoreRow = () => {
    };
    update = () => {
    };

    constructor(props) {
        super(props);
        this.openEditor = this.openEditor.bind(this);
        this.deleteConfirmation = this.deleteConfirmation.bind(this);
        this.setProps(props);
    }

    setProps(props) {
        const {
            dayStatus,
            disabled,
            index,
            schedule,
            removeRow,
            restoreRow,
            update,
            date,
            existing,
            editable = true
        } = props;
        this.dayStatus = dayStatus;
        this.index = index;
        this.disabled = disabled;
        this.schedule = schedule;
        this.existing = existing;
        this.date = date;
        this.editable = editable;
        this.removeRow = removeRow;
        this.restoreRow = restoreRow;
        this.update = update;
        this.data = JSON.parse(JSON.stringify(this.schedule));
        this.data.file = null;
    }

    shouldComponentUpdate(nextProps, nextState) {
        this.setProps(nextProps);
        return true;
    }

    openEditor() {
        const startTime = this.data.startAt ? this.data.startAt.split(" ")[1] : "";
        const finishTime = this.data.finishAt ? this.data.finishAt.split(" ")[1] : "";
        Swal.fire({
            title: 'Редактирование урока',
            showCancelButton: true,
            confirmButtonText: 'Применить',
            cancelButtonText: 'Отменить',
            confirmButtonColor: "rgb(192, 217, 165)",
            customClass: {
                confirmButton: style.modalButton,
                cancelButton: style.modalButton,
            },
            html:
                `<div class=${style.modalForm}>
                    <label for="modal_startAt">Время</label>
                    <div style="justify-content: center">
                        ${this.existing ? `<b style="font-size: 1.5rem">${startTime}</b>` : ""}
                        <input class=${style.modalInput} style="text-align: center" type="${this.existing ? "hidden" : "time"}" id="modal_startAt" value="${startTime}"
                placeholder="Введите данные"/>
                        <span style="align-self: center">-</span>
                        ${this.existing ? `<b style="font-size: 1.5rem">${finishTime}</b>` : ""}
                        <input class=${style.modalInput} style="text-align: center" type="${this.existing ? "hidden" : "time"}" id="modal_finishAt" value="${finishTime}"
                placeholder="Введите данные"/>
                        </div>
                        <label for="modal_subjectId">Предмет</label>
                        <div style="justify-content: center">
                        ${this.existing ? `<b style="font-size: 1.5rem">${this.schedule.subject.name}</b><input type="hidden" value="${this.data.subjectId}" id="modal_subjectId">` :
                    `<select class=${style.modalInput} id="modal_subjectId">
                            <option value="">-</option>
                                ${TeacherSchedule.subjects.map(subject => `
                                <option ${this.data.subjectId === subject.id ? "selected" : ""} value="${subject.id}">${subject.name}</option>
                                `)}
                            </select>`}
                        </div>
                        <label for="modal_theme">Тема</label>
                        <div>
                            <textarea class=${style.modalInput} id="modal_theme" rows="3"
                                  placeholder="Введите данные">${this.data.theme ?? ''}</textarea>
                        </div>
                        <label for="modal_homeTask">Домашнее задание</label>
                        <div>
                            <textarea class=${style.modalInput} id="modal_homeTask" rows="3"
                                  placeholder="Введите данные">${this.data.homeTask ?? ''}</textarea>
                        </div>
                        <label for="modal_comment">Комментарий</label>
                        <div>
                            <textarea class=${style.modalInput} id="modal_comment" rows="3"
                                  placeholder="Введите данные">${this.data.comment ?? ''}</textarea>
                        </div>
                        ${this.schedule.file && this.schedule.file.path ? `
                        <div style="display: block; margin: 10px">
                            <div style="justify-content: center">
                                <a href="${baseUrl}/storage/${this.schedule.file.path}">${this.schedule.file.name}</a>
                            </div>
                        </div>
                        ` : ""}
                        <div style="display: flex">
                            <div style="display: block; flex: 1">
                                <label>${(!this.schedule.file || !this.schedule.file.path) ? 'Прикрепить файл' : 'Обновить файл'}</label>
                                <div>
                                    <input class=${style.modalInput} id="modal_file" type="file"
                                          placeholder="Выберите файл"/>
                                </div>
                            </div>
                            ${this.schedule.file && this.schedule.file.path ? `
                            <div style="display: block; flex: 1">
                                <p style="font-size: 1rem">Удалить сохраненный файл</p>
                                <input id="modal_remove_file" type="checkbox" ${this.data.fileRemove ? "checked" : ''}/>
                            </div>
                            ` : ""}
                        </div>
                    </div>`,
            didRender: (popup) => {
                if (this.data.file && !this.data.file.scheduleId) {
                    let container = new DataTransfer();
                    container.items.add(this.data.file);
                    document.querySelector('#modal_file').files = container.files;
                }
            },
            preConfirm: () => {
                return new Promise((resolve) => {
                    let startAt = ScheduleItem.getValue(document.getElementById('modal_startAt').value);
                    let finishAt = ScheduleItem.getValue(document.getElementById('modal_finishAt').value);
                    if (startAt != null) startAt = `${this.date} ${startAt}`;
                    if (finishAt != null) finishAt = `${this.date} ${finishAt}`;
                    let subjectId = document.getElementById('modal_subjectId').value;
                    if (subjectId != null && subjectId !== "") subjectId = parseInt(subjectId);
                    else subjectId = null;
                    const theme = document.getElementById('modal_theme').value;
                    const homeTask = document.getElementById('modal_homeTask').value;
                    const comment = document.getElementById('modal_comment').value;
                    const files = document.getElementById('modal_file').files;
                    const file = files.length > 0 ? files[0] : null;
                    const fileRemove = document.getElementById('modal_remove_file')?.checked ?? false;
                    resolve({
                        startAt,
                        finishAt,
                        subjectId,
                        theme,
                        homeTask,
                        comment,
                        file,
                        fileRemove
                    });
                })
            }
        }).then((result) => {
            if (result.isConfirmed) {
                const data = result.value;
                Object.keys(data).forEach(key => this.data[key] = ScheduleItem.getValue(data[key]));
                const compare = (name) => {
                    return this.data[name] !== this.schedule[name];
                }

                this.update({
                    changed: data["fileRemove"] != null || data["file"] != null ||
                        compare("startAt") || compare("finishAt") ||
                        compare("subjectId") || compare("theme") ||
                        compare("homeTask") || compare("comment"),
                    schedule: this.data
                });

                this.forceUpdate();
            }
        });
    }

    static getValue(value) {
        if (value === '') return null;
        return value;
    }

    deleteConfirmation() {
        Swal.fire({
            title: 'Подтвердите удаление',
            showCancelButton: true,
            confirmButtonText: 'Удалить',
            cancelButtonText: 'Отменить',
            confirmButtonColor: "rgb(192, 217, 165)",
            customClass: {
                confirmButton: style.modalButton,
                cancelButton: style.modalButton,
            },
        }).then(result => {
            if (result.isConfirmed) {
                this.removeRow();
            }
        });
    }

    render() {
        if (this.schedule.removed) return "";
        const subjectId = this.data.subjectId;
        let subjectName = subjectId ? TeacherSchedule.subjects.find(s => s.id === subjectId) : null;
        if (subjectName == null) subjectName = "-";
        else subjectName = subjectName.name;

        const actual = !this.data.startAt || (this.data.startAt && (new Date(this.data.startAt)).getTime() >= Date.now());
        return (
            <tr style={this.disabled ? {background: "#ffeeee"} : this.existing ? {} : {background: "#eeffee"}}>
                <td className={style.rowNumber}>{this.disabled ? "" : (this.index + 1)}</td>
                <td>
                    <div style={{display: "flex", flexDirection: "column"}}>
                        {`${this.data.startAt ? getStringTime(new Date(this.data.startAt)) : "xx:xx"} - ${this.data.finishAt ? getStringTime(new Date(this.data.finishAt)) : "xx:xx"}` ?? ""}
                        {!actual ?
                            <span className={style.dayStatus}
                                  style={this.dayStatus ? {
                                      margin: 0,
                                      background: "#C0D9A5"
                                  } : {margin: 0}}>Проведен</span> : ""
                        }
                    </div>
                </td>
                <td style={{padding: "5px 5px"}}>
                    <div>{subjectName}</div>
                </td>
                <td style={{padding: "5px 5px"}}>
                    <div style={{
                        maxHeight: "3rem",
                        lineHeight: "1rem",
                        overflow: "auto",
                        wordBreak: "break-all"
                    }}>{this.data.theme ?? '-'}</div>
                </td>
                <td style={{padding: "5px 5px"}}>
                    <div style={{
                        maxHeight: "3rem",
                        lineHeight: "1rem",
                        overflow: "auto",
                        wordBreak: "break-all"
                    }}>{this.data.homeTask ?? '-'}</div>
                </td>
                <td style={{padding: "5px 5px", position: "relative"}}>
                    <div style={{
                        maxHeight: "3rem",
                        lineHeight: "1rem",
                        overflow: "auto",
                        wordBreak: "break-all"
                    }}>{this.data.comment ?? '-'}</div>
                </td>
                {this.dayStatus ?
                    !this.disabled ?
                        <td>
                            <div style={{display: "flex"}}>
                                {this.editable ?
                                    <button className={style.deleteButton} onClick={this.openEditor}>
                                        <img style={{filter: "invert(0.5) sepia(1) hue-rotate(50deg)", margin: "5px"}}
                                             className={style.deleteButtonIcon}
                                             src={"" + userIcon} alt=""/>
                                    </button> : ""}
                                <button className={style.deleteButton} onClick={this.deleteConfirmation}>
                                    <img className={style.deleteButtonIcon} src={"" + deleteIcon} alt=""/>
                                </button>
                            </div>
                        </td> : <td>
                            <div style={{display: "flex"}}>
                                <button className={style.deleteButton} onClick={this.restoreRow}>
                                    <img src={"" + resetIcon} alt=""/>
                                </button>
                            </div>
                        </td>
                    :
                    <td>-</td>}
            </tr>
        );
    }
}
