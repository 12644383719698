import style from "./CarouselStyle.module.css";
import React from "react";
import scheduleIcon from '../../Images/scheduleIcon.png';

export function Carousel({option, selectedLabel, icon = true, selectOption, options, time = true}) {
    const iterator = [...Array(5).keys()];
    const optionIndex = options.findIndex(o => o?.value === option);
    if (optionIndex == null) return "";
    const offset = 2 - optionIndex;
    return (
        <div className={style.carousel}>
            <button className={style.carouselButton} onClick={(e) => {
                e.preventDefault();
                selectOption(options[optionIndex - 1].value);
            }} disabled={!options[optionIndex - 1]}>←
            </button>
            {
                iterator.map((i) => {
                    const item = options[i - offset];
                    const exist = (item != null);
                    const current = exist && (item.value === option);
                    return (
                        <div key={"carousel_item_" + i}
                             className={`${style.carouselItem} ${current ? '' : `${time && i - offset < optionIndex ? style.carouselSecondary : ""} ${style.mobileHidden}`}`}
                             style={current ? {textAlign: "center", fontSize: "140%"} : {}}>
                            {(exist ? <h2 style={{userSelect: "none", cursor: "pointer", color: "#012E2B"}}
                                          onClick={() => selectOption(item.value)}>{item.label}</h2> : "")}
                            {current && selectedLabel ?
                                <div style={{display: "flex", justifyContent: "center"}}>
                                    {icon ?
                                        <img
                                            style={{
                                                filter: "invert(0.5) sepia(1) hue-rotate(50deg)",
                                                height: "25px",
                                                marginRight: "10px"
                                            }}
                                            src={"" + scheduleIcon}/> : ""}
                                    <span
                                        style={{
                                            color: "#618935",
                                            fontSize: "70%",
                                            textWrap: "nowrap",
                                            fontWeight: "bold",
                                            lineHeight: "25px"
                                        }}>{selectedLabel}</span>
                                </div> : ""}
                        </div>
                    );
                })
            }
            <button className={style.carouselButton} onClick={(e) => {
                e.preventDefault();
                selectOption(options[optionIndex + 1].value);
            }} disabled={!options[optionIndex + 1]}>→
            </button>
        </div>
    );
}
