import React, {useContext, useState} from 'react';
import style from './EditProfileStyle.module.css';
import {AuthContext} from "../../Providers/AuthProvider";
import {Navigate} from "react-router-dom";
import {UserContext} from "../../Providers/UserProvider";
import {ApiInstance} from "../../api";
import {FormRedirectBlocker} from "../Journal/Journal";

export default function EditProfile() {
    const {token} = useContext(AuthContext);
    const [user, setUser] = useContext(UserContext);
    if (!token) return <Navigate to="/auth" replace={true}/>;
    if (!user) return <div className={style.loader}></div>;
    return <EditProfileForm token={token} user={user} setUser={setUser}/>;
};

function EditProfileForm({token, user, setUser}) {
    const name = user?.name.split(" ") ?? [];
    const [changedUser, setChangedUser] = useState(getDefaultValues());
    const [errorMessages, setErrorMessages] = useState({
        email: "",
        password: "",
        secondName: "",
        firstName: "",
        patronymic: "",
        phone: "",
    });
    const personalChanged = changedUser.firstName[1] || changedUser.secondName[1] || changedUser.patronymic[1] || changedUser.phone[1];
    const anyChanged = personalChanged || changedUser.email[1] || changedUser.password[1];
    FormRedirectBlocker(anyChanged);

    function getDefaultValues() {
        return {
            email: [user?.email, false, false],
            phone: [user?.phone ?? "", false, false],
            password: ["", false],
            secondName: [name[0] ?? "", false],
            firstName: [name[1] ?? "", false],
            patronymic: [name[2] ?? "", false],
        };
    }

    function setValue(e) {
        const changed = getDefaultValues()[e.target.name][0] !== e.target.value;
        setChangedUser({
            ...changedUser,
            [e.target.name]: [e.target.value, changed, changedUser[e.target.name][2] ?? false]
        });
        if (!changed && errorMessages[e.target.name]) {
            setErrorMessages({...errorMessages, [e.target.name]: ""});
        }
    }

    function updateEmail() {
        setChangedUser({...changedUser, email: [changedUser.email[0], changedUser.email[1], true]});
        ApiInstance(token).post('personal', {email: changedUser.email[0]}).then(() => {
            setUser({...user, email: changedUser.email[0]});
            setChangedUser({...changedUser, email: [changedUser.email[0], false, false]});
            setErrorMessages({...errorMessages, email: ""});
        }).catch(e => {
            setChangedUser({...changedUser, email: [changedUser.email[0], changedUser.email[1], false]});
            setErrorMessages({...errorMessages, email: e.response.data.errors.email});
        });
    }

    function updatePassword() {
        setChangedUser({...changedUser, password: [changedUser.password[0], changedUser.password[1], true]});
        ApiInstance(token).post('personal', {
            password: changedUser.password[0],
            passwordRepeat: changedUser.password[0]
        }).then(() => {
            setChangedUser({...changedUser, password: ["", false, false]})
            setErrorMessages({...errorMessages, password: ""});
        }).catch(e => {
            setChangedUser({...changedUser, password: [changedUser.password[0], changedUser.password[1], false]});
            setErrorMessages({...errorMessages, password: e.response.data.errors.password[0]});
        });
    }

    function discardChanges() {
        setChangedUser(getDefaultValues());
        setErrorMessages({
            email: "",
            password: "",
            secondName: "",
            firstName: "",
            patronymic: "",
            phone: "",
        });
    }

    function saveChanges() {
        const name = `${changedUser.secondName[0].replace(/ /g, '')} ${changedUser.firstName[0].replace(/ /g, '')} ${changedUser.patronymic[0].replace(/ /g, '')}`;
        setChangedUser({...changedUser, firstName: [changedUser.firstName[0], changedUser.firstName[1], true]});
        ApiInstance(token).post('personal', {name: name, phone: changedUser.phone[0]}).then(() => {
            setUser({...user, name: name, phone: changedUser.phone[0]});
            setChangedUser({
                ...changedUser,
                firstName: [changedUser.firstName[0], false, false],
                secondName: [changedUser.secondName[0], false, false],
                patronymic: [changedUser.patronymic[0], false, false],
            });
            setErrorMessages({...errorMessages, firstName: "", secondName: "", patronymic: "", phone: ""});
        }).catch(e => {
            setChangedUser({...changedUser, firstName: [changedUser.firstName[0], changedUser.firstName[1], false]});
            if (e.response.data.errors.name) {
                errorMessages.firstName = e.response.data.errors.name[0];
            }
            if (e.response.data.errors.phone) {
                errorMessages.phone = e.response.data.errors.phone[0];
            }
            setErrorMessages(errorMessages);
        });
    }

    return (
        <div className={style.main}>
            <h1 style={{textDecoration: "underline #c6d8a6", fontSize: "2.5rem"}}>Редактирование профиля</h1>
            <h3 className={style.title}>Основные данные аккаунта:</h3>
            <div className={style.topic}>
                <ButtonField
                    name="email"
                    label="Ваш логин:"
                    placeholder="Введите свою почту"
                    submit={updateEmail}
                    error={errorMessages.email}
                    changed={changedUser.email[1]}
                    loading={changedUser.email[2]}
                    update={setValue}
                    defaultValue={changedUser.email[0]}
                />
                <ButtonField
                    name="password"
                    label="Ваш пароль:"
                    placeholder="Введите свой пароль"
                    submit={updatePassword}
                    error={errorMessages.password}
                    changed={changedUser.password[1]}
                    loading={changedUser.password[2]}
                    update={setValue}
                    defaultValue={changedUser.password[0]}
                />
            </div>

            <h3 className={style.title}>Информация об ученике:</h3>
            <div className={style.topic}>
                <Field
                    label="Фамилия:"
                    name="secondName"
                    placeholder="Введите свою фамилию"
                    update={setValue}
                    disabled={changedUser.firstName[2]}
                    defaultValue={changedUser.secondName[0]}
                    error={errorMessages.secondName}
                />
                <Field
                    label="Имя:"
                    name="firstName"
                    placeholder="Введите своё имя"
                    update={setValue}
                    disabled={changedUser.firstName[2]}
                    defaultValue={changedUser.firstName[0]}
                    error={errorMessages.firstName}
                />
                <Field
                    label="Отчество:"
                    name="patronymic"
                    placeholder="Введите своё отчество"
                    update={setValue}
                    disabled={changedUser.firstName[2]}
                    defaultValue={changedUser.patronymic[0]}
                    error={errorMessages.patronymic}
                />
                <Field
                    label="Контактный телефон:"
                    name="phone"
                    placeholder="Введите свой номер телефона"
                    update={setValue}
                    disabled={changedUser.firstName[2]}
                    defaultValue={changedUser.phone[0]}
                    error={errorMessages.phone}
                />
            </div>
            <div style={{display: "flex"}}>
                {personalChanged ?
                    <Button loading={changedUser.firstName[2]} buttonStyle={{background: "#9cb968", marginTop: "15px"}}
                            submit={saveChanges}
                            label="Сохранить изменения"/> : ""}
                {anyChanged ?
                    <Button buttonStyle={{background: "#d4d4d4", color: "#000", marginTop: "15px", marginLeft: "15px"}}
                            submit={discardChanges} label="Отменить"/> : ""}
            </div>
        </div>
    );
}

function Button({buttonStyle, loading, submit, label, disabled}) {
    return (
        <button className={style.button}
                style={buttonStyle}
                onClick={submit}
                disabled={disabled || loading}>
            <div>{label}</div>
            {loading ? <div className={style.loader}></div> : ""}
        </button>
    );
}

function ButtonField({label, defaultValue, changed, loading, error, update, placeholder, name, submit}) {
    return (
        <div className={style.item}>
            <label htmlFor={name}>{label}</label>
            <div className={style.topicForm}>
                <input type="text" name={name} value={defaultValue}
                       className={`${style.input} ${error ? style.errorInput : ''}`}
                       placeholder={placeholder}
                       disabled={loading}
                       onChange={update}/>
                <Button
                    buttonStyle={(changed ? {background: "#9cb968"} : {background: "#d4d4d4", color: "#000"})}
                    submit={submit}
                    disabled={!changed}
                    label="Сохранить"
                    loading={loading}
                />
            </div>
            <small className={style.error}>{error}</small>
        </div>
    );
}

export function Field({type = "text", label, defaultValue, disabled, error, update, placeholder, name}) {
    return (
        <div className={style.item}>
            <label htmlFor={name}>{label}</label>
            <div className={style.topicForm}>
                <input type={type}
                       name={name}
                       value={defaultValue}
                       disabled={disabled}
                       className={`${style.input} ${error ? style.errorInput : ''}`}
                       placeholder={placeholder}
                       onChange={update}/>
            </div>
            <small className={style.error}>{error}</small>
        </div>
    );
}