import React, {useContext, useState} from 'react';
import style from './StatisticStyle.module.css';
import {Carousel} from "../Carousel/Carousel";
import {AuthContext} from "../../Providers/AuthProvider";
import {Navigate} from "react-router-dom";
import {ApiInstance} from "../../api";
import {getDate} from "../Schedule/StudentCalendar";

const MONTHS = [
    "янв.",
    "февр.",
    "март",
    "апр.",
    "май",
    "июнь",
    "июль",
    "авг.",
    "сент.",
    "окт.",
    "ноябрь",
    "дек.",
];

function getDateString(date) {
    return String(date.getDate()).padStart(2, "0") + " " + MONTHS[date.getMonth()] + " " + String(date.getFullYear());
}

function startDate(p) {
    return getDateString(p.sp);
}

function endDate(p) {
    return getDateString(p.ep);
}

function fullDate(p) {
    return startDate(p) + " - " + endDate(p)
}

Statistic.cache = null;

export function Statistic() {
    const {token} = useContext(AuthContext);
    const [chosenPart, setChosenPart] = useState(0);
    const [loading, setLoading] = useState(false);
    if (!token) return <Navigate to="/auth" replace={true}/>;
    if (loading) return <div className={style.loader}></div>;
    if (Statistic.cache == null) {
        loadStatistics();
        setLoading(true);
        return <div className={style.loader}></div>;
    }

    function getLabel() {
        if (chosenPart < Statistic.cache.quarters.length) return fullDate(Statistic.cache.quarters[chosenPart]);
        return startDate(Statistic.cache.quarters[0]) + "-" + endDate(Statistic.cache.quarters[Statistic.cache.quarters.length - 1]);
    }

    const selectedLabel = getLabel();

    function loadStatistics() {
        ApiInstance(token).get('/statistics').then(r => {
                Statistic.cache = {};
                Statistic.cache.quarters = r.data.quarters.map(quarter => {
                    return {
                        sp: getDate(new Date(quarter.startAt)),
                        ep: getDate(new Date(quarter.endAt)),
                    }
                });
                Statistic.cache.carousel = Statistic.cache.quarters.map((quarter, i) => {
                    return {value: i, label: (i + 1) + " четверть"}
                });
                Statistic.cache.carousel.push({value: Statistic.cache.quarters.length, label: "Итоговая"});
                Statistic.cache.parts = {};
                Statistic.cache.parts.marks = r.data.quarters.map(quarter => quarter.subjects);
                Statistic.cache.parts.marks.push(r.data.period.subjects);

                function getAttendanceString(attendance) {
                    return `${attendance[0]}/${attendance[1]}`;
                }

                function parseItemAttendances(part) {
                    return [
                        ...part.subjects.map(subject => {
                            return {
                                id: subject.id,
                                name: subject.name,
                                value: getAttendanceString(subject.attendances)
                            };
                        }),
                        {
                            id: 0,
                            name: "Итоговая посещаемость",
                            value: getAttendanceString(part.attendances)
                        }
                    ];
                }

                Statistic.cache.parts.attendances = [
                    ...r.data.quarters.map(quarter => parseItemAttendances(quarter)),
                    parseItemAttendances(r.data.period)
                ];
                setLoading(false);
            }
        )
        ;
    }

    return (
        <div className={style.container}>
            <StatisticCarousel item={chosenPart} selectItem={setChosenPart} selectedLabel={selectedLabel}/>
            <StatisticData selected={chosenPart}/>
        </div>
    );
}

function StatisticData({selected}) {
    return (<div className={style.statistic}>
        <StatisticsGroup name={"marks_" + selected} title="Успеваемость:" label="Успеваемость:"
                         items={Statistic.cache.parts.marks[selected]}/>
        <StatisticsGroup name={"attendances_" + selected} title="Посещаемость предметов:" label="Посещаемость:"
                         items={Statistic.cache.parts.attendances[selected]}/>
    </div>);
}

function StatisticsGroupTitle({secondary, label}) {
    return (<div className={`${style.item} ${style.mainItem} ${secondary ? style.topItem : ''}`}>
        <span className={style.mainItem}>Предмет:</span>
        <span className={style.mainItem}>{label}</span>
    </div>);
}

function StatisticsGroup({name, title, label, items}) {
    return (
        <>
            <h1 className={style.title}>{title}</h1>
            <div className={style.table}>
                <StatisticsGroupTitle label={label}/>
                <StatisticsGroupTitle label={label} secondary={true}/>
                {items.map((c, index) => (
                    <div key={`${name}_${index}`} className={style.item}>
                        <span>{c.name}</span>
                        <div className={style.line}></div>
                        <span>{c.value}</span>
                    </div>
                ))}
            </div>
        </>
    )
}

function StatisticCarousel({item, selectItem, selectedLabel}) {
    const options = Statistic.cache.carousel;
    return Carousel({option: item, selectOption: selectItem, options, selectedLabel});
}