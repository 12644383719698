import React, {useContext, useReducer, useState} from 'react';
import style from './SubjectsListStyle.module.css';
import {SaveActions} from "../Schedule/TeacherSchedule";
import {AuthContext} from "../../Providers/AuthProvider";
import {ApiInstance} from "../../api";
import {GroupContext} from "../../Providers/GroupProvider";
import Swal from "sweetalert2";
import {Navigate} from "react-router-dom";
import cancelIcon from "../../Images/cancelIcon.png";
import resetIcon from "../../Images/resetIcon.png";
import {FormRedirectBlocker} from "../Journal/Journal";

SubjectsList.cache = {};
SubjectsList.added = {};
SubjectsList.removed = [];

export function SubjectsList() {
    const {token} = useContext(AuthContext);
    const [group] = useContext(GroupContext);
    const [subjects, setSubjects] = useState(null);
    const [changes, setChanges] = useState(false);
    FormRedirectBlocker(changes);

    if (!token) return <Navigate to="/auth" replace={true}/>;
    if (!group) return;
    if (subjects == null) loadSubjects();
    if (!subjects) return;

    function loadSubjects() {
        if (subjects != null) return;
        ApiInstance(token).get(`/periods/${group.periodId}/subjects?usages=true`).then(r => {
            SubjectsList.cache = {};
            r.data.forEach(subject => {
                if (!SubjectsList.cache[subject.type]) SubjectsList.cache[subject.type] = [];
                SubjectsList.cache[subject.type].push(subject);
            });
            setSubjects(true);
        });
    }

    function update() {
        const hasChanges = Object.keys(SubjectsList.added).length > 0 || SubjectsList.removed.length > 0;
        if (hasChanges !== changes) setChanges(hasChanges);
    }

    function save() {
        let requests = [];
        SubjectsList.removed.forEach(id => {
            requests.push(ApiInstance(token).delete('/subject/' + id));
        });
        Promise.all(requests).then(() => {
            let requests = [];
            Object.keys(SubjectsList.added).forEach(type => {
                SubjectsList.added[type].forEach(name => {
                    requests.push(ApiInstance(token).post(`/periods/${group.periodId}/subject/create`, {type, name}));
                });
            });
            Promise.all(requests).then(() => {
                Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 2000
                }).fire({
                    icon: "success",
                    title: "Данные сохранены"
                });
                SubjectsList.added = {};
                SubjectsList.removed = [];
                setSubjects(null);
                setChanges(false);

            });
        });
    }

    function cancel() {
        SubjectsList.added = {};
        SubjectsList.removed = [];
        setChanges(false);
    }

    return (
        <div>
            <ItemsManager update={update} type={0} title="Предметы:" addLabel="Добавить новый предмет"
                          noItemsLabel="Нет предметов" createLabel="Добавление предмета"/>
            <ItemsManager update={update} type={1} title="Мероприятия:" addLabel="Добавить новое мероприятие"
                          noItemsLabel="Нет мероприятий" createLabel="Добавление мероприятия"/>
            <div style={{marginTop: "30px"}}></div>
            <SaveActions hasChanges={changes} save={save} cancel={cancel}/>
        </div>
    );
}

function ItemsManager({type, title, addLabel, update, noItemsLabel, createLabel}) {
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    function create() {
        Swal.fire({
            title: createLabel,
            input: "text",
            showCancelButton: true,
            confirmButtonText: 'Добавить',
            cancelButtonText: 'Отменить',
            confirmButtonColor: "rgb(192, 217, 165)",
            customClass: {
                confirmButton: style.modalButton,
                cancelButton: style.modalButton,
            },
        }).then(r => {
            if (r.isConfirmed) submit(r.value);
        });
    }

    function submit(name) {
        if (!SubjectsList.added[type]) SubjectsList.added[type] = [];
        SubjectsList.added[type].push(name);
        update();
        forceUpdate();
    }

    function removeExisting(i) {
        SubjectsList.removed.push(i);
        update();
        forceUpdate();
    }

    function removeAdded(i) {
        SubjectsList.added[type].splice(i, 1);
        update();
        forceUpdate();
    }

    function restore(i) {
        const index = SubjectsList.removed.indexOf(i);
        if (index !== -1) SubjectsList.removed.splice(index, 1);
        update();
        forceUpdate();
    }

    return (
        <div className={style.group}>
            <h1 className={style.title}>{title}</h1>
            <div className={style.list}>
                {SubjectsList.cache[type]?.map((c, i) => <Item key={"subjectGroup_" + type + "_" + i}
                                                               name={c.name}
                                                               hasSchedules={c.has}
                                                               isNew={false}
                                                               restore={() => restore(c.id)}
                                                               remove={() => removeExisting(c.id)}
                                                               isRemoved={SubjectsList.removed.includes(c.id)}/>)}
                {SubjectsList.added[type]?.map((c, i) => <Item key={"subjectGroupAdded_" + type + "_" + i}
                                                               isNew={true}
                                                               remove={() => removeAdded(i)}
                                                               name={c}/>)}
                {!SubjectsList.cache[type] && !SubjectsList.added[type] ? <h2>{noItemsLabel}</h2> : ""}
            </div>
            <br/>
            <button className={style.buttonAdd} onClick={create}>+ {addLabel}</button>
        </div>
    );
}

function Item({name, hasSchedules = false, isNew, isRemoved, remove, restore}) {
    function ItemMenu() {
        if (hasSchedules) return;
        if (isRemoved) {
            Swal.fire({
                title: name,
                showCancelButton: true,
                confirmButtonText: 'Вернуть',
                cancelButtonText: 'Отменить',
                confirmButtonColor: "rgb(192, 217, 165)",
                customClass: {
                    confirmButton: style.modalButton,
                    cancelButton: style.modalButton,
                },
            }).then(r => {
                if (r.isConfirmed && restore) restore();
            });
        } else {
            Swal.fire({
                title: name,
                showCancelButton: true,
                confirmButtonText: 'Удалить',
                cancelButtonText: 'Отменить',
                confirmButtonColor: "#f99",
                customClass: {
                    confirmButton: style.modalButton,
                    cancelButton: style.modalButton,
                },
            }).then(r => {
                if (r.isConfirmed) remove();
            });
        }
    }

    return <div onClick={ItemMenu}
                style={{background: isNew ? "rgb(238, 255, 238)" : (isRemoved ? "#f99" : null), cursor: hasSchedules ? null : "pointer"}}
                className={style.item}>
        <div style={{display: "flex", flexDirection: "row"}}>
            {name}
            {!hasSchedules ? <img style={{
                marginLeft: "0.2rem", height: "1rem", width: "1rem", alignSelf: "center"
            }} src={"" + (isRemoved ? resetIcon : cancelIcon)} alt=""/> : ""}
        </div>
    </div>;
}