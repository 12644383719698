import React, {useContext, useReducer, useState} from 'react';
import style from './StudentListStyle.module.css';
import deleteIcon from '../../Images/deleteIcon.png';
import cancelIcon from '../../Images/cancelIcon.png';
import resetIcon from '../../Images/resetIcon.png';
import {SaveActions} from "../Schedule/TeacherSchedule";
import Swal from "sweetalert2";
import {AuthContext} from "../../Providers/AuthProvider";
import {ApiInstance} from "../../api";
import {GroupContext} from "../../Providers/GroupProvider";
import {FormRedirectBlocker} from "../Journal/Journal";

StudentList.cache = {};
StudentList.archivings = [];
StudentList.restorings = [];
StudentList.joinCancelations = [];

export function StudentList() {
    const {token} = useContext(AuthContext);
    const [group] = useContext(GroupContext);
    const [students, setStudents] = useState(null);
    const [changed, setChanged] = useState(false);
    FormRedirectBlocker(changed);
    if (!group) return;
    if (students == null) {
        loadStudents();
        return;
    }
    if (!students) return (<div className={style.loader}></div>);

    function loadStudents() {
        if (students === false) return;
        setStudents(false);
        ApiInstance(token).get(`/periods/${group.periodId}/students`).then(r => {
            StudentList.cache = r.data;
            setStudents(true);
        });
    }

    function CreateNewStudent() {
        Swal.fire({
            title: "Приглашение нового ученика",
            showCancelButton: true,
            confirmButtonText: 'Применить',
            cancelButtonText: 'Отменить',
            confirmButtonColor: "rgb(192, 217, 165)",
            customClass: {
                confirmButton: style.modalButton,
                cancelButton: style.modalButton,
            },
            html:
                `<div>
                    <label for="modal_startAt">Фамилия</label>
                    <div style="justify-content: center;display: flex;">
                        <input class=${style.modalInput} style="text-align: center" type="text" id="modal_surname"
                placeholder="Введите фамилию"/>
                    </div>
                    <label for="modal_startAt">Имя</label>
                    <div style="justify-content: center;display: flex;">
                        <input class=${style.modalInput} style="text-align: center" type="text" id="modal_name"
                placeholder="Введите имя"/>
                    </div>
                    <label for="modal_startAt">Отчество</label>
                    <div style="justify-content: center;display: flex;">
                        <input class=${style.modalInput} style="text-align: center" type="text" id="modal_patronymic"
                placeholder="Введите отчество"/>
                    </div>
                    <label for="modal_startAt">Email</label>
                    <div style="justify-content: center;display: flex;">
                        <input class=${style.modalInput} style="text-align: center" type="email" id="modal_email"
                placeholder="Введите email ученика"/>
                    </div>
                </div>`,
            inputValidator: (value) => {
                return new Promise((resolve) => {
                    resolve("alsdkjad;slf");
                });
            },
            preConfirm: async () => {
                const fields = ["name", "surname", "patronymic", "email"];
                const data = fields.map(key => document.getElementById(`modal_${key}`).value.replace(/ /g, ''));
                if (data[0] === '') {
                    Swal.showValidationMessage('<i class="fa fa-info-circle"></i> Укажите имя');
                } else if (data[1] === '') {
                    Swal.showValidationMessage('<i class="fa fa-info-circle"></i> Укажите фамилию');
                } else if (data[3] === '') {
                    Swal.showValidationMessage('<i class="fa fa-info-circle"></i> Укажите email');
                } else {
                    try {
                        await ApiInstance(token).post("/register/request/create", {
                            name: `${data[1]} ${data[0]} ${data[2]}`,
                            email: data[3],
                            periodId: group.periodId
                        });
                        Swal.mixin({
                            toast: true,
                            position: "top-end",
                            showConfirmButton: false,
                            timer: 2000
                        }).fire({
                            icon: "success",
                            title: "Заявка на регистрацию отправлена"
                        });
                    } catch (r) {
                        let response = r.response;
                        if (response.status === 422) {
                            if (response.data.errors.email) Swal.showValidationMessage('<i class="fa fa-info-circle"></i> ' + response.data.errors.email[0]);
                            if (response.data.errors.name) Swal.showValidationMessage('<i class="fa fa-info-circle"></i> ' + response.data.errors.name[0]);
                            if (response.data.errors.periodId) Swal.showValidationMessage('<i class="fa fa-info-circle"></i> ' + response.data.errors.periodId[0]);
                        }
                    }
                }
            }
        }).then(() => 0);
    }

    function update() {
        const hasChanges = StudentList.archivings.length > 0 || StudentList.restorings.length > 0 || StudentList.joinCancelations.length > 0;
        if (hasChanges !== changed) setChanged(hasChanges);
    }

    function save() {
        let requests = [];
        StudentList.joinCancelations.forEach(index => {
            requests.push(ApiInstance(token).post("/register/request/cancel", {email: StudentList.cache.waiting[index].email}));
        });

        StudentList.archivings.forEach(index => {
            requests.push(ApiInstance(token).post(`/period/${group.periodId}/students/${StudentList.cache.nominal[index].id}/archive`));
        });
        Promise.all(requests).then((r) => {

            StudentList.archivings = [];
            StudentList.joinCancelations = [];

            let requests = [];
            StudentList.restorings.forEach(index => {
                requests.push(ApiInstance(token).post(`/period/${group.periodId}/students/${StudentList.cache.archived[index].id}/restore`));
            });

            Promise.all(requests).then((r) => {
                Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 2000
                }).fire({
                    icon: "success",
                    title: "Изменения сохранены"
                });
                setChanged(false);
                setStudents(null);
                StudentList.restorings = [];
            }).catch(e => {
                setStudents(null);
                const r = e.response;
                if (r.status === 422) {
                    if (r.data.errors) {
                        let string = "";
                        Object.keys(r.data.errors).forEach(key => {
                            string = string + "\n" + r.data.errors[key][0];
                        });
                        Swal.fire({
                            title: "Ошибка запроса, данные неверные",
                            text: string,
                            icon: "error"
                        });
                    }
                }
            });
        }).catch((e) => console.log(e.response));
    }

    function cancel() {
        StudentList.archivings = [];
        StudentList.restorings = [];
        StudentList.joinCancelations = [];
        setChanged(false);
    }

    return (
        <div>
            <h1 className={style.title}>Текущие ученики</h1>
            <span className={style.secondary} style={{fontSize: "1.1rem", marginTop: "15px", fontWeight: "200"}}>
                Наши текущие ученики, здесь вы можете добавить ученика, для этого прийдется отправить запрос родителям
                на регистрацию. В случае, если ученик переводится в другую школу, вы можете отправить его в архив.
            </span>
            <div className={style.list}>
                {StudentList.cache.nominal.map((s, index) => (
                    <StudentCard index={index} key={"nominal_" + index} name={s.name} text={`${s.email} | ${s.phone ?? "-"}`}
                                 update={update}/>
                ))}
                {StudentList.cache.waiting.map((s, index) => (
                    <StudentCard index={index} key={"waiting_" + index} name={s.name} text={s.email} update={update}
                                 join={true}/>
                ))}
                <div className={style.listItem} style={{display: "flex"}}>
                    <button onClick={CreateNewStudent}
                            className={style.buttonItem}
                    >
                        <span className={style.buttonIcon}> + </span>
                        <span style={{marginLeft: "15px", fontWeight: 600, fontSize: "1.1rem"}}>Добавить нового<br/>ученика</span>
                    </button>
                </div>
            </div>
            <h1 style={{marginTop: "4rem"}} className={style.title}>Архив учеников</h1>
            <span className={style.secondary} style={{fontSize: "1.1rem", marginTop: "15px", fontWeight: "200"}}>
                Список учеников, с которыми мы попрощались. Они не будут отображаться в журнале, а родителям перестанет
                приходить сообщения в личном кабинете. Для удобства, учеников в архиве можно восстановить в нашей школе,
                для дальнейшей учебы, либо чтобы поднять историю оценок.
            </span>
            <div className={style.list}>
                {StudentList.cache.archived.map((s, index) => (
                    <StudentCard index={index} key={"archived_" + index} name={s.name} text={`${s.email} | ${s.phone ?? "-"}`}
                                 update={update}
                                 main={false}/>
                ))}
                {StudentList.cache.archived.length === 0 ? <h2>В архиве нет учеников</h2> : ""}
            </div>
            <div style={{marginTop: "4rem"}}></div>
            <SaveActions hasChanges={changed} save={save} cancel={cancel}/>
        </div>
    );
}

function StudentCard({index, text, name, main = true, join = false, update}) {
    const [, forceUpdate] = useReducer(x => x + 1, 0);
    const changed = isChanged();

    function isChanged() {
        if (join) return StudentList.joinCancelations.includes(index);
        else if (main) return StudentList.archivings.includes(index);
        return StudentList.restorings.includes(index);
    }

    function removeIndex(key) {
        const i = StudentList[key].indexOf(index);
        if (i !== -1) StudentList[key].splice(i, 1);
    }

    function addIndex(key) {
        StudentList[key].push(index);
    }

    function buttonAction() {
        if (join) {
            if (changed) removeIndex("joinCancelations");
            else addIndex("joinCancelations");
        } else if (main) {
            if (changed) removeIndex("archivings");
            else addIndex("archivings");
        } else {
            if (changed) removeIndex("restorings");
            else addIndex("restorings");
        }
        update();
        forceUpdate();
    }

    function buttonText() {
        if (join) {
            if (changed) return "Отменить отзыв запроса";
            else return "Отменить запрос на регистрацию";
        } else if (main) {
            if (changed) return "Отменить удаление";
            else return "В архив";
        } else {
            if (changed) return "Отменить восстановление";
            else return "Восстановить";
        }
    }

    function buttonIcon() {
        if (join) {
            if (changed) return resetIcon;
            else return cancelIcon;
        } else if (main) {
            if (changed) return resetIcon;
            else return deleteIcon;
        } else {
            if (changed) return cancelIcon;
            return resetIcon;
        }
    }

    function changedColor() {
        if (changed) {
            if (main) return "#fdd";
            else return "#dfd";
        }
        return null;
    }

    return (
        <div key={`student_${1}`} className={style.listItem}>
            <div className={style.item} style={{background: changedColor()}}>
                <h3 className={style.name}>{name}</h3>
                <span className={style.secondary}>{text}</span>
                <div className={style.deleteBlock}>
                    <button className={style.deleteButton} onClick={buttonAction}>
                        <img style={{alignSelf: "center"}} src={"" + buttonIcon()} alt="."/>
                        <span style={{
                            alignSelf: "start",
                            marginLeft: "5px",
                            justifySelf: "center",
                            display: "flex"
                        }}>{buttonText()}</span>
                    </button>
                </div>
            </div>
        </div>
    );
}