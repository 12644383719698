import React, {useContext, useState} from 'react';
import style from './Calendar.module.css';
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import {ApiInstance} from "../../api";
import {AuthContext} from "../../Providers/AuthProvider";
import {groupBy} from "../Journal/Journal";
import {Navigate} from "react-router-dom";
import {Carousel} from "../Carousel/Carousel";
import {getSeptember} from "../../Pages/Diary/DiaryPage";
import {getNormalWeekDay, StudentDiary} from "../Diary/StudentDiary";
import {GroupContext} from "../../Providers/GroupProvider";

const DAYS = [
    'Воскресенье',
    'Понедельник',
    'Вторник',
    'Среда',
    'Четверг',
    'Пятница',
    'Суббота',
];
const MONTHS = [
    "января",
    "февраля",
    "марта",
    "апреля",
    "мая",
    "июня",
    "июля",
    "августа",
    "сентября",
    "октября",
    "ноября",
    "декабря",
];
const monthsTitles = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь",
]

StudentCalendar.cache = {};//years.months - data
StudentCalendar.holidays = {};

// возвращает день начала и день конца недели указанного дня этой недели
StudentCalendar.getMonthRange = function (date) {
    const startDay = getDate(new Date(date.setDate(1)));
    return {
        startDay,
        endDay: new Date(startDay.getFullYear(), startDay.getMonth() + 1, 0),
    };
}

function monthDiff(d1, d2) {
    var months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
}

export function StudentCalendar() {
    const {token} = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [chosenDate, setChosenDate] = useState(null);
    const [month, setMonth] = useState(0);
    const [group] = useContext(GroupContext);

    if (!token) return <Navigate to="/auth" replace={true}/>;
    if (!group) return;
    const firstDayDate = new Date(Date.parse(getSeptember(new Date())));
    const lastDate = group.period.range[1] ? StudentCalendar.getMonthRange(new Date(Date.parse(group.period.range[1]))).startDay : null;
    const lastMonth = lastDate ? monthDiff(firstDayDate, lastDate) : null;
    const currentDayDate = getDate(new Date(firstDayDate));
    currentDayDate.setMonth(firstDayDate.getMonth() + month);
    const firstMonthDate = StudentCalendar.getMonthRange(currentDayDate).startDay;

    if (chosenDate == null) {
        setChosenDate(new Date());
        loadMonth(new Date(Date.now()));
    }

    if (chosenDate != null && chosenDate.getMonth() !== firstMonthDate.getMonth()) {
        setChosenDate(firstMonthDate);
        loadMonth(firstMonthDate);
    }

    function loadMonth(dayOfMonth) {
        const normal = getDate(dayOfMonth).toISOString().split('T')[0];
        const date = getDate(dayOfMonth);
        const monthNumber = date.getMonth();
        const yearNumber = date.getFullYear();
        if (StudentCalendar.cache[yearNumber] && StudentCalendar.cache[yearNumber][monthNumber]) return;
        loadHolidays(yearNumber, monthNumber, normal);
        setLoading(true);
        ApiInstance(token).get('/schedule/month?day=' + normal).then(r => {
            let data = r.data;
            data.forEach(item => {
                const startAt = new Date(item.startAt);
                const finishAt = new Date(item.finishAt);
                item.day = startAt.getDate();
                item.title = item.subject.name;
                item.time = `${getStringTime(startAt)} - ${getStringTime(finishAt)}`;
            });
            data.sort((a, b) => new Date(a.startAt) - new Date(b.startAt));
            data = groupBy(data, (item) => item.day);
            Object.keys(data).forEach(day => data[day] = groupBy(data[day], (item) => item.subject.type));
            if (!StudentCalendar.cache[yearNumber]) StudentCalendar.cache[yearNumber] = {};
            StudentCalendar.cache[yearNumber][monthNumber] = data;
            setTimeout(() =>  setLoading(false), 200);
            if (chosenDate?.getMonth() !== currentDayDate.getMonth()) {
                setChosenDate(firstMonthDate);
            }
        });
    }

    function loadHolidays(year, month, normal) {
        ApiInstance(token).get(`/holiday/month/${group.periodId}?day=${normal}`).then(r => {
            let data = r.data;
            data.forEach(item => {
                const startAt = new Date(item.day);
                item.dayIndex = startAt.getDate();
            });
            data.sort((a, b) => new Date(a.day) - new Date(b.day));
            data = groupBy(data, (item) => item.dayIndex);
            Object.keys(data).forEach(day => data[day] = data[day][0]);
            if (!StudentCalendar.holidays[year]) StudentCalendar.holidays[year] = {};
            StudentCalendar.holidays[year][month] = data;
            console.log(StudentCalendar.holidays);
        })
    }

    let data = {};
    let isWeekend = false;
    let isHoliday = false;
    if (chosenDate != null) {
        const date = chosenDate;
        const monthNumber = date.getMonth();
        const yearNumber = date.getFullYear();
        const dayNumber = date.getDate();
        if (StudentCalendar.cache[yearNumber] && StudentCalendar.cache[yearNumber][monthNumber]) {
            data = StudentCalendar.cache[yearNumber][monthNumber][dayNumber] ?? {};
        }
        const day = chosenDate?.getDay();
        isWeekend = day != null ? (day === 0 || day === 6) : false;
        if (StudentCalendar.holidays[yearNumber] && StudentCalendar.holidays[yearNumber][monthNumber] && StudentCalendar.holidays[yearNumber][monthNumber][dayNumber]) {
            isHoliday = true;
        }
    }

    const firstDayMonth = firstDayDate.getMonth();
    const months = [...Array(5).keys()].map(i => {
        const value = i + month - 2;
        if (value < 0) return null;
        if (value > lastMonth) return null;
        return {label: monthsTitles[(value + firstDayMonth) % 12], value};
    });

    return (
        <div className={style.wrapper}>
            <h1 style={{textDecoration: "underline #c6d8a6", fontSize: "3rem"}}>Расписание</h1>
            <Carousel option={month} selectedLabel={currentDayDate?.getFullYear()} icon={false} time={false}
                      selectOption={setMonth}
                      options={months}/>
            <div className={style.main}>
                <div style={{position: "relative"}}>
                    {loading ?
                        <div style={{
                            position: "absolute",
                            left: 0,
                            top: 0,
                            width: "100%",
                            height: "100%",
                            background: "rgba(255, 255, 255, 0.7)"
                        }}>
                            <div className={style.loader}></div>
                        </div> : ""}
                    <Calendar
                        showNavigation={false}
                        activeStartDate={firstMonthDate}
                        prev2Label={null}
                        next2Label={null}
                        minDetail="year"
                        tileDisabled={({date}) => date.getMonth() !== firstMonthDate.getMonth()}
                        tileClassName={({date}) => {
                            const day = date.getDay();
                            const isWeekend = (day === 6 || day === 0);
                            const monthNumber = date.getMonth();
                            const yearNumber = date.getFullYear();
                            const isHoliday = StudentCalendar.holidays[yearNumber] && StudentCalendar.holidays[yearNumber][monthNumber] && StudentCalendar.holidays[yearNumber][monthNumber][date.getDate()];
                            return chosenDate.getTime() === date?.getTime() ? `${style.tile} ${style.active_tile} ${isWeekend || isHoliday ? style.weekend_tile : ""}` : `${style.tile} ${isWeekend || isHoliday ? style.weekend_tile : ""}`
                        }}
                        tileContent={({date}) => {
                            const day = date.getDay();
                            const isWeekend = (day === 6 || day === 0);
                            const monthNumber = date.getMonth();
                            const yearNumber = date.getFullYear();
                            const isHoliday = StudentCalendar.holidays[yearNumber] && StudentCalendar.holidays[yearNumber][monthNumber] && StudentCalendar.holidays[yearNumber][monthNumber][date.getDate()];
                            const dayNumber = date.getDate();
                            if (StudentCalendar.cache[yearNumber] && StudentCalendar.cache[yearNumber][monthNumber]) {
                                data = StudentCalendar.cache[yearNumber][monthNumber][dayNumber] ?? {};
                            }
                            return <div>
                                {isWeekend && !isHoliday ? <b>Выходной</b> : ""}
                                {isHoliday ? <b>Каникулы</b> : ""}
                                {data[0]?.length > 0 ? <p>{data[0]?.length} предмета</p> : ""}
                                {data[1]?.length > 0 ? <p>{data[1]?.length} меропр-я</p> : ""}
                            </div>;
                        }}
                        className={style.calendar}
                        showNeighboringDecade={false}
                        onClickDay={(value) => setChosenDate(value)}
                    />
                </div>
                <div className={style.description}>
                    {chosenDate ?
                        <div style={{display: "flex", flexDirection: "column"}}>
                            <div className={style.date}>
                                <div className={style.dayNumb}>
                                    <h1 style={{fontWeight: 800}}>
                                        {String(chosenDate.getDate()).padStart(2, "0")}
                                    </h1>
                                </div>
                                <div className={style.dayDesc}>
                                    {MONTHS[chosenDate.getMonth()]} {String(chosenDate.getFullYear())} <br/>
                                    <span className={style.secondary}>
                            {DAYS[chosenDate.getDay()]}
                        </span>
                                </div>
                            </div>
                            {isWeekend && !isHoliday ? <h3>Выходной</h3> : ""}
                            {isHoliday ? <h3>Каникулы</h3> : ""}
                        </div> : ""}
                    <TopicSchedules data={data[0]} name="Предметы:" absenceLabel="Нет занятий"/>
                    <TopicSchedules data={data[1]} name="Мероприятия:" absenceLabel="Нет мероприятий"/>
                </div>
            </div>
        </div>
    );
}

export function getStringTime(date) {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;
}

export function getDate(date) {
    return new Date(date.getTime() + Math.abs(date.getTimezoneOffset() * 60000));
}

function TopicSchedules({data, name, absenceLabel}) {
    return (
        <div>
            <h3 className={style.title}>{name}</h3>
            <ul className={style.list}>
                {data?.map((d) => (
                    <li className={style.item} key={d.id}>
                        <div style={{width: "125px", color: "#6C827F"}}>{d.time}</div>
                        <div style={{width: "125px"}}>{d.title}</div>
                    </li>
                ))}
                {!data || data.length === 0 ? <h3>{absenceLabel}</h3> : ""}
            </ul>
        </div>
    );
}