import React, {createContext, useContext, useState} from "react";
import {AuthContext} from "./AuthProvider";
import {ApiInstance} from "../api";

export const UserContext = createContext(null);

export function CheckUser(user) {
    return user.student || user.teacher;
}

export const UserProvider = ({children}) => {
    const [user, setUser] = useState(null);
    const {token, setAuth} = useContext(AuthContext);
    if (token && !user) {
        ApiInstance(token).get("/personal").then(r => {
            const data = r.data;
            data.student = data.roles.includes("student");
            data.teacher = data.roles.includes("teacher");
            setUser(data);
        }).catch(r => {
            if (r.status === 401) {
                setAuth(null);
            }
        });
    }
    if (!token && user) setUser(null);
    return <UserContext.Provider value={[user, setUser]}>{children}</UserContext.Provider>;
};
