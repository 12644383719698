import React, {useContext} from 'react';
import {UserContext} from "../../Providers/UserProvider";
import {Navigate, Route, Routes} from "react-router-dom";
import {getSeptember, NavigationLink} from "../Diary/DiaryPage";
import {StudentList} from "../../Components/StudentList/StudentList";
import {Journal} from "../../Components/Journal/Journal";
import {AuthContext} from "../../Providers/AuthProvider";

const JournalPage = () => {
    const {token} = useContext(AuthContext);
    const [user] = useContext(UserContext);
    if (!token) return <Navigate to="/auth" replace={true}/>;
    if (!user) return;
    if (user?.student) return <Navigate to="/"/>;
    Journal.cacheClear();
    return (<div style={{padding: "15px 5%"}}>
        <h1 style={{textDecoration: "underline #c6d8a6", fontSize: "3rem"}}>Журнал</h1>
        <div style={{display: "flex"}}>
            <NavigationLink path="/journal" label="Журнал"/>
            <NavigationLink path="/journal/studentlist" label="Ученики"/>
        </div>
        <Routes>
            <Route path="/*" element={<Navigate to="/journal"/>}/>
            <Route path="/" element={<Journal firstWeekDay={getSeptember(new Date())}/>}/>
            <Route path="/studentlist" element={<StudentList/>}/>
        </Routes>
    </div>);
};

export default JournalPage;