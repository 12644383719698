import {createContext} from "react";
import {useCookies} from "react-cookie";

export const AuthContext = createContext({
    token: null,
});

export const AuthProvider = ({children}) => {
    const [cookies, setCookie, removeCookie] = useCookies(['token']);

    function setAuth(token) {
        removeCookie('token', {path: "/"});
        if (token != null) setCookie('token', token, {path: "/"});
    }

    return (
        <AuthContext.Provider value={{token: cookies.token, setAuth}}>
            {children}
        </AuthContext.Provider>
    );
};
