import React, {useContext} from 'react';
import style from './DiaryStyles.module.css';
import {Statistic} from "../../Components/Statistic/Statistic";
import {UserContext} from "../../Providers/UserProvider";
import {StudentDiary} from "../../Components/Diary/StudentDiary";
import {Link, Navigate, Route, Routes, useLocation} from "react-router-dom";
import {AuthContext} from "../../Providers/AuthProvider";

export function DiaryPage() {
    const {token} = useContext(AuthContext);
    const [user] = useContext(UserContext);
    if (!token) return <Navigate to="/auth" replace={true}/>
    if (!user) return;
    if (!user?.student) return <Navigate to="/"/>;
    StudentDiary.cache = {};
    Statistic.cache = null;
    return (
        <>
            <div style={{padding: "15px 5%", display: "flex"}}>
                <NavigationLink path="/diary" label="Расписание"/>
                <NavigationLink path="/diary/statistics" label="Статистика"/>
            </div>
            <main>
                <Routes>
                    <Route path="/" element={<StudentDiary firstWeekDay={getSeptember(new Date())}/>}/>
                    <Route path="/statistics" element={<Statistic/>}/>
                </Routes>
            </main>
        </>
    );
}

export function getSeptember(day) {
    // Получаем 1 сентября нужного года в переменную d
    // проверка нужного года: (day.getMonth() < 8) ? day.getFullYear()-1 : day.getFullYear()
    // если месяц указанной даты меньше 8 (т.к. отсчет месяцев в жс начинается с 0), то прошлый год, т.к. это январь-август,
    // иначе этот год, т.к. это сентябрь-декабрь
    let d = new Date((day.getMonth() < 8) ? day.getFullYear() - 1 : day.getFullYear(), 8, 1, 0, 0, 0);
    // т.к. дни недели в жс начинаются с вск, нужно сделать сдвиг элементов во вспомогательном массиве
    let h = [6, 7, 1, 2, 3, 4, 5]
    // Теперь зная 1 сентября нужного года мы переносим на след. неделю и вычитаем нынешний день недели, чтобы получить пн
    d.setDate(d.getDate() + 7 - h[d.getDay()]);
    return d;
}

export function NavigationLink({path, label}) {
    const location = useLocation().pathname;
    return (<Link
        className={location !== path ? style.changeButton : `${style.changeButton} ${style.changeButtonActive}`}
        to={path}>{label}</Link>);
}