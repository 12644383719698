import {useContext} from "react";
import {AuthContext} from "../../Providers/AuthProvider";
import {CheckUser, UserContext} from "../../Providers/UserProvider";
import {GroupContext} from "../../Providers/GroupProvider";
import {Navigate} from "react-router-dom";
import {baseUrl} from "../../api";

export function WrongSite() {
    const {token} = useContext(AuthContext);
    const [user] = useContext(UserContext);
    const [, hasContent] = useContext(GroupContext);
    if (!token) return <Navigate to="/auth" replace={true}/>;
    if (!user) return;
    const normal = CheckUser(user);
    if (normal && hasContent) return <Navigate to="/"/>;
console.log(13)
    // если у учителя нет контента
    if (normal) {
        return <div>
            <h1>У вас пока нет класса</h1>
        </div>;
    }
    // если админ зарегался на сайте
    return <div>
        <h1>Данный сайт не подходит администраторам и менеджера</h1>
        <p>Для вас есть специальная админ панель</p>
        <a href={baseUrl}>Перейти</a>
    </div>;
}