import React, {useContext, useState} from 'react';
import style from './DiaryStyle.module.css';
import {getDate, getStringTime} from "../Schedule/StudentCalendar";
import {ApiInstance, baseUrl} from "../../api";
import {AuthContext} from "../../Providers/AuthProvider";
import {Navigate} from "react-router-dom";
import {Carousel} from "../Carousel/Carousel";
import {groupBy} from "../Journal/Journal";
import Swal from "sweetalert2";
import {GroupContext} from "../../Providers/GroupProvider";

const DAYS = [
    'Понедельник',
    'Вторник',
    'Среда',
    'Четверг',
    'Пятница',
    'Суббота',
    'Воскресенье',
];
const MONTHS = [
    "янв.",
    "февр.",
    "март",
    "апр.",
    "май",
    "июнь",
    "июль",
    "авг.",
    "сент.",
    "окт.",
    "ноябрь",
    "дек.",
];

export const Marks = ['Не был', 'Не усвоено', 'Частично усвоено', 'Усвоено', '2', '3', '4', '5'];

StudentDiary.cache = {};//week number-data

// Перевод индекса дня недели из формата воскресенье-понедельник в понедельник-воскресение
export function getNormalWeekDay(day) {
    return --day >= 0 ? day : 6;
}

// возвращает день начала и день конца недели указанного дня этой недели
StudentDiary.getWeekRange = function (date) {
    const dayOfWeek = getNormalWeekDay(date.getDay()); // индекс дня в неделе
    const dayOfMonth = date.getDate(); // индекс дня в месяце
    const first = dayOfMonth - dayOfWeek;
    return {
        startDay: getDate(new Date(date.setDate(first))),
        endDay: getDate(new Date(date.setDate(first + 6))),
    };
}

StudentDiary.weeksDifference = function (dt2, dt1) {
    let diff = (dt2.getTime() - dt1.getTime()) / 1000; // разница дат в миллисекундах
    diff /= (60 * 60 * 24 * 7); // перевод из миллисекунд в недели
    return Math.floor(diff);
}

export function StudentDiary({firstWeekDay}) {
    const {token} = useContext(AuthContext);
    const [group] = useContext(GroupContext);
    const [week, setWeek] = useState(null);
    const [_, setLoading] = useState(false);

    if (!token) return <Navigate to="/auth" replace={true}/>;
    if (!group) return;
    const firstWeekDayDate = StudentDiary.getWeekRange(new Date(Date.parse(firstWeekDay))).startDay;
    const lastWeekDate = group.period.range[1] ? StudentDiary.getWeekRange(new Date(Date.parse(group.period.range[1]))).startDay : null;
    const lastWeek = lastWeekDate ? StudentDiary.weeksDifference(lastWeekDate, firstWeekDayDate) : null;
    if (week == null) {
        const nominal = StudentDiary.weeksDifference(getDate(new Date()), firstWeekDayDate);
        const diff = lastWeek != null && lastWeek > 0 ? Math.min(lastWeek, nominal) : 0;
        setWeek(diff);
        loadWeek(diff);
        return "";
    }

    function loadWeek(week) {
        if (StudentDiary.cache[week] != null) return;
        let cd = new Date(firstWeekDayDate);
        const dateDiff = firstWeekDayDate.getDate() + week * 7;
        cd.setDate(dateDiff);
        const normal = getDate(cd).toISOString().split('T')[0];
        setLoading(true);
        ApiInstance(token).get('/schedule/week?day=' + normal).then(r => {
            let data = r.data;
            data.forEach(item => {
                const startAt = new Date(item.startAt);
                const finishAt = new Date(item.finishAt);
                item.day = getNormalWeekDay(startAt.getDay());
                item.title = item.subject.name;
                item.time = `${getStringTime(startAt)} - ${getStringTime(finishAt)}`;
            });
            data.sort((a, b) => new Date(a.startAt) - new Date(b.startAt));
            data = groupBy(data, (item) => item.day);
            StudentDiary.cache[week] = data;

            setLoading(false);
        });
    }

    function updateWeek(week) {
        setWeek(week);
        loadWeek(week);
    }

    let days = [];
    const dateDiff = firstWeekDayDate.getDate() + week * 7;
    for (let i = 0; i < 6; i++) {
        let cd = new Date(firstWeekDayDate);
        cd.setDate(dateDiff + i);
        cd.setHours(23);
        cd.setMinutes(59);
        cd.setSeconds(59);
        days.push({
            name: DAYS[i],
            date: String(cd.getDate()).padStart(2, "0") + " " + MONTHS[cd.getMonth()] + " " + String(cd.getFullYear()).padStart(2, "0"),
            status: cd.getTime() > Date.now(),
        })
    }

    return (
        <div className={style.container}>
            <DiaryCarousel
                week={week}
                lastWeek={Math.max(0, lastWeek)}
                setWeek={updateWeek}
                days={days}/>
            {days.map((day, index) => (
                <Day
                    key={"day_" + index}
                    index={index}
                    day={day}
                    data={StudentDiary.cache[week] ? StudentDiary.cache[week][index] ?? [] : []}
                />
            ))}
        </div>
    );
}

export function DayPanel({day, show, setShow}) {
    return (
        <div className={style.dayPanel}>
            <div className={style.dayPanelDate}>
                <h1 style={{margin: 0}} className={style.dayName}>{day.name}</h1>
                <span className={style.secondary}>{day.date}</span>
                {(day.status) ? "" : (<span className={style.dayStatus}>День завершился</span>)}
            </div>
            {day.status ? <div style={{flex: 1}}></div> :
                <div style={{
                    display: "flex", flexDirection: "column", justifyContent: "center", flex: "1",
                    marginLeft: "5px",
                    marginRight: "5px"
                }}>
                    <div className={style.line}></div>
                </div>}
            {day.status ? "" :
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    marginLeft: "10px"
                }}>
                    <button className={style.dayButton} onClick={(e) => setShow(!show)}>
                        {show ? "Свернуть расписание" : "Развернуть расписание"}
                    </button>
                </div>}
        </div>
    );
}

function Day({day, data}) {
    const [show, setShow] = useState(false);

    return (<div>
        <DayPanel day={day} show={show} setShow={setShow}/>
        <div className={style.schedule} style={{display: show || day.status ? "flex" : "none"}}>
            <table className={!day.status ? style.status : {}}>
                <thead>
                <tr>
                    <th>№</th>
                    <th>Время</th>
                    <th>Предмет/мероприятие</th>
                    <th className={style.descriptionColumn}>Тема, домашнее задание, комментарий</th>
                    <th>Оценка</th>
                </tr>
                </thead>
                <tbody>
                {data && data.length > 0 ? data.map((schedule, index) => (
                        <ScheduleItem key={"schedule_" + schedule.id} number={index + 1} dayStatus={day.status}
                                      schedule={{
                                          time: schedule.time,
                                          subject: {name: schedule.title},
                                          theme: schedule.theme ?? "-",
                                          homeTask: schedule.homeTask ?? "-",
                                          comment: schedule.comment ?? "-",
                                          marks: schedule.marks ?? [],
                                          file: schedule.file
                                      }}/>
                    )) :
                    <tr>
                        <td colSpan="6">Нет занятий</td>
                    </tr>}
                </tbody>
            </table>
        </div>
    </div>);
}

export function DiaryCarousel({week, setWeek, days, lastWeek}) {
    const options = [...Array(5).keys()].map(i => {
        if (week + i - 2 < 0) return null;
        if (lastWeek != null && week + i - 2 > lastWeek) return null;
        return {value: week + i - 2, label: (week + i - 2 + 1) + " неделя"};
    });
    return Carousel({option: week, selectedLabel: `${days[0].date} - ${days[5].date}`, selectOption: setWeek, options});
}

function ScheduleItem({dayStatus, number, schedule}) {
    const marks = schedule.marks ?? [];

    function openDataWindow() {
        Swal.fire({
            title: schedule.subject.name,
            html: `
                <ul style="list-style-type: none">
                <li style="font-size: 1.5rem; font-weight: bold">Время</li>
                <li>${schedule.time}</li>
                <li style="font-size: 1.5rem; font-weight: bold">Тема</li>
                <li>${schedule.theme}</li>
                <li style="font-size: 1.5rem; font-weight: bold">Домашнее задание</li>
                <li>${schedule.homeTask}</li>
                <li style="font-size: 1.5rem; font-weight: bold">Комментарий</li>
                <li>${schedule.comment}</li>
                ${schedule.file ?
                `<li style="font-size: 1.5rem; font-weight: bold">Файл</li>
                     <a target="_blank" href="${baseUrl}/storage/${schedule.file.path}">${schedule.file?.name}</a>` : ""
            }
                </ul>
            `,
            confirmButtonColor: "rgb(192, 217, 165)",
        });
    }

    return (
        <tr onClick={openDataWindow}>
            <td className={style.rowNumber}>{number}</td>
            <td>
                <div style={{display: "flex", flexDirection: "column"}}>
                    {schedule.time ?? ""}
                    {schedule.startAt && (new Date(schedule.startAt)).getTime() < Date.now() ?
                        <span className={style.dayStatus}
                              style={dayStatus ? {
                                  margin: 0,
                                  background: "#C0D9A5"
                              } : {margin: 0}}>Завершился</span> : ""
                    }
                </div>
            </td>
            <td>{schedule.subject.name}</td>
            <td className={style.descriptionColumn}>
                <b>Тема:</b>{schedule.theme}<br/>
                <br/>
                <b>Дом. задание:</b> {schedule.homeTask}<br/>
                <br/>
                <b>Комментарий:</b> {schedule.comment}<br/>
                {schedule.file ?
                    <div><b>Файл:</b><a target="_blank"
                                        href={baseUrl + "/storage/" + schedule.file.path}>{schedule.file.name}</a></div>
                    : ""
                }
            </td>
            <td>{marks.map((mark, i) => Marks[mark.type] + (i < marks.length - 1 ? " / " : ""))}{marks.length === 0 ? "-" : ""}</td>
        </tr>
    );
}