import React, {useContext} from 'react';
import style from './UserMenuStyle.module.css';
import diaryIcon from '../../Images/diaryIcon.png';
import scheduleIcon from '../../Images/scheduleIcon.png';
import messageIcon from '../../Images/messageIcon.png';
import userIcon from '../../Images/userIcon.png';
import quitIcon from '../../Images/quitIcon.png';
import {Link, Navigate, useLocation} from "react-router-dom";
import {CheckUser, UserContext} from "../../Providers/UserProvider";
import {AuthContext} from "../../Providers/AuthProvider";
import {logout} from "../../api";
import {GroupContext} from "../../Providers/GroupProvider";

function NavigationLink({path, label, icon}) {
    const location = useLocation().pathname;
    return (
        <li className={(location.includes(path)) ? `${style.liActive} ${style.listItem}` : `${style.listItem}`}>
            <Link className={style.link} to={path}>
                <img src={"" + icon} style={{marginRight: "15px", alignSelf: "center"}} alt="icon"/>
                {label}
            </Link>
        </li>);
}

function UserLink({path, user}) {
    const [group, content] = useContext(GroupContext);
    const location = useLocation().pathname;
    if (!content && location !== "/wrong") return <Navigate to="/wrong"/>;
    if (!CheckUser(user) && location !== "/wrong") return <Navigate to="/wrong"/>;
    if (user.student) {
        return (<li className={(location === path) ? `${style.liActive} ${style.listItem}` : `${style.listItem}`}>
            <div style={{alignSelf: "center"}}>
                <img src={"" + userIcon} style={{marginRight: "15px"}} alt="icon"/>
            </div>
            <Link className={style.userLink} to={path}>
                {user?.name}
                <span className={style.secondary}>Класс {group?.fullName}</span>
            </Link>
        </li>);
    }
    return (<li className={(location === path) ? `${style.liActive} ${style.listItem}` : `${style.listItem}`}>
        <div className={style.secondary}
             style={{fontSize: "2.5rem", marginRight: "5px", textWrap: "nowrap"}}>{group?.teacherFullName}</div>
        <Link className={style.userLink} to={path}>
            {user?.name}
            <span className={style.secondary}>Учебный период {group?.yearsRangeString}</span>
        </Link>
    </li>);
}

export function UserMenu() {
    const {token, setAuth} = useContext(AuthContext);
    const [user] = useContext(UserContext);
    if (!token || !user) return "";
    return (
        <div className={style.main}>
            <div className={style.item}>
                <ul className={`${style.list} ${style.mobileHide} ${style.spis}`}>
                    {user.student ? <NavigationLink icon={diaryIcon} path="/diary" label="Дневник"/> : ""}
                    <NavigationLink icon={scheduleIcon} path="/schedule" label="Расписание"/>
                    {!user.student ? <NavigationLink icon={diaryIcon} path="/journal" label="Журнал"/> : ""}
                    {!user.student ? <NavigationLink icon={diaryIcon} path="/quarters" label="Четверти"/> : ""}
                    <NavigationLink icon={messageIcon} path="№" label="Сообщения"/>
                </ul>
            </div>
            <div className={style.item}>
                <ul className={`${style.list} ${style.spis}`}>
                    <UserLink path="/profile" user={user}/>
                    <li className={`${style.mobileHide} ${style.listItem}`}>
                        <button className={style.link} onClick={() => logout(token, setAuth)} style={{
                            background: "transparent",
                            border: "none",
                            cursor: "pointer"
                        }}>
                            <div style={{alignSelf: "center"}}>
                                <img src={"" + quitIcon} style={{marginRight: "15px"}} alt="icon"/>
                            </div>
                            Выйти
                        </button>
                    </li>
                </ul>
            </div>
        </div>
    );
}