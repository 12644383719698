import React, {useContext} from 'react';
import {StudentCalendar} from "../../Components/Schedule/StudentCalendar";
import {UserContext} from "../../Providers/UserProvider";
import {Navigate, Route, Routes} from "react-router-dom";
import {TeacherSchedule} from "../../Components/Schedule/TeacherSchedule";
import {getSeptember, NavigationLink} from "../Diary/DiaryPage";
import {SubjectsList} from "../../Components/SubjectsList/SubjectsList";
import {AuthContext} from "../../Providers/AuthProvider";

export function SchedulePage() {
    const {token} = useContext(AuthContext);
    const [user] = useContext(UserContext);
    if (!token) return <Navigate to="/auth" replace={true}/>;
    if (!user) return;
    if (user.student) return (<StudentCalendar/>);
    TeacherSchedule.cacheClear();
    return (<div style={{padding: "15px 5%"}}>
        <h1 style={{textDecoration: "underline #c6d8a6", fontSize: "3rem"}}>Расписание</h1>
        <div style={{display: "flex"}}>
            <NavigationLink path="/schedule" label="Расписание"/>
            <NavigationLink path="/schedule/subjects" label="Список предметов и регулярных мероприятий"/>
        </div>
        <Routes>
            <Route path="/*" element={<Navigate to="/schedule"/>}/>
            <Route path="/" element={<TeacherSchedule firstWeekDay={getSeptember(new Date())}/>}/>
            <Route path="/subjects" element={<SubjectsList/>}/>
        </Routes>
    </div>);
}