import axios from "axios";

export const baseUrl = "https://diary.chastnaya-shkola.ru"
export const apiUrl = baseUrl + "/api";

export const ApiInstance = (auth = false) => axios.create({
    baseURL: apiUrl,
    headers: {
        accept: 'application/json',
        authorization: auth ? `Bearer ${auth}` : ''
    }
});

export function logout(token, setAuth, callback = () => {}) {
    ApiInstance(token).get('/logout').then(r => {
        setAuth(null);
        callback();
    });
}