import React, {useContext, useState} from 'react';
import style from './RegistrationStyle.module.css';
import {Navigate, useSearchParams} from "react-router-dom";
import {ApiInstance} from "../../api";
import {Field} from "../EditProfile/EditProfile";
import {AuthContext} from "../../Providers/AuthProvider";

export function Registration() {
    const {token, setAuth} = useContext(AuthContext);
    const [searchParams] = useSearchParams();
    const registerToken = searchParams.get('token');
    const registerEmail = searchParams.get('email');
    const [validated, setValidated] = useState(null);
    const [agree, setAgree] = useState(false);
    const [showCond, setShowCond] = useState(false);

    const [changedUser, setChangedUser] = useState(getDefaultValues());

    const [errorMessages, setErrorMessages] = useState({
        password: "",
        passwordRepeat: "",
        phone: "",
        agree: ""
    });

    if (token || !registerToken || !registerEmail) return <Navigate to="/"/>;
    if (validated == null) {
        ApiInstance().get(`/register/request/${registerEmail}/${registerToken}`).then(r => {
            setValidated(r.data.valid);
            if (r.data.valid) {
                const name = r.data.name.split(" ");
                setChangedUser({
                    ...changedUser,
                    secondName: name[0] ?? "",
                    firstName: name[1] ?? "",
                    patronymic: name[2] ?? ""
                });
            }
        });
        return <div className={style.loader}></div>;
    }
    if (!validated) {
        return <h1>Время действия ссылки истекло, попросите учителя отправить повторную заявку.</h1>
    }

    function getDefaultValues() {
        return {
            phone: "",
            password: "",
            passwordRepeat: "",
            secondName: "",
            firstName: "",
            patronymic: "",
        };
    }

    function setValue(e) {
        setChangedUser({
            ...changedUser,
            [e.target.name]: e.target.value
        });
        if (errorMessages[e.target.name]) {
            setErrorMessages({...errorMessages, [e.target.name]: ""});
        }
    }

    const createAccount = async () => {
        if (changedUser.password === "") {
            setErrorMessages({...errorMessages, password: "Укажите пароль"});
            return;
        }
        if (changedUser.password === changedUser.passwordRepeat) {
            setErrorMessages({...errorMessages, passwordRepeat: ""});
        } else {
            setErrorMessages({...errorMessages, passwordRepeat: "пароли не совпадают"});
            return;
        }
        if (!agree) {
            setErrorMessages({
                ...errorMessages,
                agree: "Для регистрации необходимо согласиться с условиями пользовательского соглашения"
            })
            return;
        }

        ApiInstance().post("/register/request", {
            email: registerEmail,
            token: registerToken,
            name: `${changedUser.secondName.replace(/ /g, '')} ${changedUser.firstName.replace(/ /g, '')} ${changedUser.patronymic.replace(/ /g, '')}`,
            ...changedUser
        }).then(r => {
            setAuth(r.data.auth);
        }).catch(r => {
            const response = r.response;
            if (response.status === 422) {
                let errors = {};
                Object.keys(response.data.errors).forEach(key => {
                    errors[key] = response.data.errors[key][0];
                });
                setErrorMessages({...errorMessages, ...errors})
            }
        });
    }

    return (
        <div className={style.main}>
            <h1 style={{textDecoration: "underline #c6d8a6", fontSize: "2.5rem"}}>Создание аккаунта ученика класса</h1>
            <h2 className={style.label} htmlFor="email">Ваш логин: {registerEmail}</h2>
            <h3 className={style.title}>Основные данные аккаунта:</h3>
            <div className={style.topic}>
                <Field label="Придумайте пароль:" name="password" placeholder="Введите свой пароль" type="password"
                       defaultValue={changedUser.password}
                       error={errorMessages.password}
                       update={setValue}/>
                <Field label="Подтвердите пароль:" name="passwordRepeat" placeholder="Введите свой пароль"
                       type="password"
                       defaultValue={changedUser.passwordRepeat}
                       error={errorMessages.passwordRepeat}
                       update={setValue}/>
            </div>

            <h3 className={style.title}>Информация об ученике:</h3>
            <div className={style.topic}>
                <Field label="Фамилия:" name="secondName" placeholder="Введите свою фамилию"
                       defaultValue={changedUser.secondName}
                       update={setValue}/>
                <Field label="Имя:" name="firstName" placeholder="Введите своё имя"
                       defaultValue={changedUser.firstName}
                       update={setValue}/>
                <Field label="Отчество:" name="patronymic" placeholder="Введите своё отчество"
                       defaultValue={changedUser.patronymic}
                       update={setValue}/>
                <Field label="Контактный телефон:" name="phone" placeholder="Введите свой номер телефона"
                       defaultValue={changedUser.phone}
                       update={setValue}/>
            </div>
            <div style={{display: "flex", marginTop: "3rem"}}>
                <button className={style.topicButton}
                        style={{background: "#9cb968"}}
                        onClick={createAccount}>
                    Создать аккаунт
                </button>
                <div style={{display: "flex", alignItems: "center"}}>
                    <input style={{margin: "1rem"}} type="checkbox" onChange={(e) => {
                        setAgree(e.target.checked);
                        if (errorMessages.agree !== "") {
                            setErrorMessages({...errorMessages, agree: ""});
                        }
                    }}/>
                    <div style={{display: "flex", flexDirection: "column", textAlign: "left"}}>
                        Нажимая кнопку "Создать аккаунт" вы принимаете
                        <div>
                            <button style={{
                                color: "rgb(156, 185, 104)",
                                justifySelf: "left",
                                textAlign: "center",
                                background: "none",
                                border: "none",
                                textDecoration: "underline",
                                cursor: "pointer"
                            }} onClick={() => setShowCond(true)}>условия пользовательского соглашения
                            </button>
                        </div>
                        {errorMessages.agree ? <span style={{color: "red"}}>{errorMessages.agree}</span> : ""}
                    </div>
                </div>
            </div>
            <div style={{
                position: "fixed", overflow: "scroll",
                top: 0,
                left: 0,
                height: "100vh",
                width: "100vw",
                display: (showCond) ? "flex" : "none",
                justifyContent: "center",
                padding: "15px 40px",
                boxSizing: "border-box"
            }}>
                <div style={{
                    width: "80%", overflow: "scroll",
                    background: "#FFF",
                    padding: "25px 40px",
                    boxSizing: "border-box",
                    boxShadow: "0px 10px 25px 4px rgba( 2, 2, 2, 0.50 )",
                    fontSize: ".9rem",
                    height: "90vh",
                    borderRadius: "15px"
                }}>

                    <div style={{display: "flex", width: "100%", justifyContent: "space-between"}}>
                        <span>
                        ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ
                        </span>
                        <button style={{
                            background: "red",
                            width: "30px",
                            height: "30px",
                            border: "none",
                            color: "white",
                            cursor: "pointer",
                        }}
                                onClick={() => setShowCond(false)}>
                            X
                        </button>
                    </div>


                    <div tabIndex="0">
                        <p>Настоящие УСЛОВИЯ ИСПОЛЬЗОВАНИЯ САЙТА являются официальным документом ИП
                            Никонорова Т.А. (ИНН 780222286400, 197375 СПб, ул. Главная, д. 26 (пом. 4, 5), далее –
                            Администратор) и определяют порядок использования посетителями сайта
                            https://chastnaya-shkola.ru/ (далее — Сайт) и обработки информации, получаемой от
                            посетителей Сайта.</p>
                        <p><strong>Начиная использование Сайта посетитель выражает свое полное и
                            безоговорочное согласие с настоящими УСЛОВИЯМИ ИСПОЛЬЗОВАНИЯ САЙТА, которые определяются
                            Администратором как публичная оферта в соответствии со статьей 437 ГК РФ.</strong>
                        </p>
                        <p>Посетитель Сайта обязан ознакомиться с настоящими УСЛОВИЯМИ ИСПОЛЬЗОВАНИЯ САЙТА
                            до начала ввода персональных данных на Сайт.</p>
                        <p>Настоящие УСЛОВИЯ ИСПОЛЬЗОВАНИЯ САЙТА могут быть изменены Администратором в
                            одностороннем порядке в любой момент без уведомления пользователя Сайта.</p>
                        <p><strong>Сайт и порядок его использования.</strong>&nbsp;Сайт представляет собой
                            комплексный объект авторских и смежных прав, все исключительные права на который принадлежат
                            правообладателю – ответственностью ИП Никонорова Т.А. (ИНН 780222286400, 197375 СПб, ул.
                            Главная, д. 26 (пом. 4, 5), далее – Администратор).</p>
                        <p><strong>Согласие на обработку персональных данных.</strong>&nbsp;В случае если
                            при использовании Сайт пользователем будет сообщена какая-либо информация, относящаяся к
                            прямо или косвенно определенному или определяемому физическому лицу (далее – персональные
                            данные), ее последующая обработка будет осуществляться в соответствии с законодательством
                            Российской Федерации. В отношении всех сообщаемых персональных данных пользователь дает
                            Администратору согласие на их обработку. Администратор обрабатывает персональные данные
                            пользователя исключительно в целях предоставления пользователю функционала Сайта,
                            размещенного на нем контента, маркетинговой, рекламной, иной информации, в целях получения
                            пользователем персонализированной (таргетированной) рекламы, исследования и анализа данных
                            пользователя, а также в целях продвижения пользователю своих товаров и услуг. Администратор
                            принимает все необходимые меры для защиты персональных данных пользователя Сайта, а также
                            предоставляет к таким данным доступ только тем работникам, подрядчикам и агентам
                            Администратора, которым эта информация необходима для обеспечения целей обработки
                            персональных данных Администратором Сайта. Раскрытие предоставленных пользователем
                            персональных данных может быть произведено лишь в соответствии с законодательством
                            Российской Федерации. В отношении всех сообщенных Администратору пользователем своих
                            персональных данных Администратор вправе осуществлять сбор, систематизацию, накопление,
                            хранение, уточнение (обновление, изменение), использование, распространение (в том числе
                            передача любым третьим лицам, включая передачу персональных данных третьим лицам на хранение
                            или в случае поручения обработки персональных данных третьим лицам), обезличивание,
                            блокирование, уничтожение, трансграничная передача, обработка с применением основных
                            способов такой обработки (хранение, запись на электронных носителях и их хранение,
                            составление перечней, маркировка) и иные действия в соответствии со статьей 3 Федерального
                            закона от 27.06.2006 № 152-ФЗ «О персональных данных». Пользователь понимает и соглашается с
                            тем, что предоставление Администратору какой-либо информации о себе, не являющейся
                            контактной и не относящейся к целям, обозначенным Администратором Сайта (не относящейся к
                            деятельности Администратора, к продвигаемым им товарам и/или услугам, к условиям
                            сотрудничества Администратора и пользователя Сайта), а ровно предоставление информации,
                            относящейся к государственной, банковской и/или коммерческой тайне, информации о расовой
                            и/или национальной принадлежности, политических взглядах, религиозных или философских
                            убеждениях, состоянии здоровья, интимной жизни пользователя Сайта или иного третьего лица
                            запрещено.</p>
                        <p><strong>Обязанности пользователя Сайта.</strong>&nbsp;Пользователь обязан при
                            посещении Сайта соблюдать положения настоящих УСЛОВИЙ ИСПОЛЬЗОВАНИЯ САЙТА и законодательства
                            Российской Федерации, в том числе:<br/>
                            В случае принятия пользователем решения о предоставлении Администратору какой-либо
                            информации (каких-либо данных), предоставлять исключительно достоверную и актуальную
                            информацию. Пользователь Сайта не вправе вводить Администратора в заблуждение в отношении
                            своей личности, сообщать ложную или недостоверную информацию о себе;<br/>
                            •Не сообщать Администратору любым образом, как с использованием Сайта, так и в ином порядке,
                            какую-либо информацию, полностью или в части относящуюся к государственной, коммерческой
                            и/или банковской тайне, фактам и информации о своей личной жизни или личной жизни третьих
                            лиц, а также не сообщать иную информацию, предоставление которой запрещено настоящими
                            УСЛОВИЯМИ ИСПОЛЬЗОВАНИЯ САЙТА;<br/>
                            •Не сообщать, не передавать и не предоставлять Администратору какую-либо информацию (в том
                            числе данные, объекты, контент и тд.) и ссылки на такую информацию если это может нарушать
                            или привести к нарушению законодательства Российской Федерации, нарушить права и интересы
                            третьих лиц. В случае наличия у пользователя сомнений относительного правомерности сообщения
                            какой-либо информации Администратору посредством Сайта, пользователь обязан воздержаться от
                            совершения данного действия;<br/>
                            •Использовать полученную от Администратора информацию (в том числе информацию, переданную
                            Администратором через Сайт) исключительно для личных целей, не передавать такую информацию
                            третьим лицам без прямого на то согласия Администратора.</p>
                        <p><strong>Ограничение ответственности Администратора.</strong>&nbsp;Сайт, как и
                            любое программное обеспечение, не свободно от ошибок. Сайт, весь функционал, включая
                            скрипты, приложения, контент и иную информацию, размещенную на сайте, поставляются на
                            условиях «КАК ЕСТЬ», со всеми недостатками, проявившимися сразу или не сразу. Администратор
                            не гарантирует и не обещает каких-либо результатов от использования Сайта. Принимая
                            настоящие УСЛОВИЯ ИСПОЛЬЗОВАНИЯ САЙТА пользователь Сайта соглашается с тем, что
                            Администратор не несет никакой ответственности за функционирование и работоспособность
                            Сайта. Администратор не несет ответственности за временные сбои и перерывы в работе Сайта и
                            вызванные такими сбоями потери информации. Администратор также не несет никакой
                            ответственности за ущерб, причиненный пользователям Сайта, явившийся результатом
                            использования Сайт. Администратор вправе использовать и распоряжаться информацией
                            пользователя для составления статистической отчетности.</p>
                    </div>
                </div>
            </div>
        </div>
    )
        ;
}