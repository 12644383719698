import React from 'react';
import style from './MainHeaderStyle.module.css';
import logo from '../../Images/logo.png';
import {Link} from "react-router-dom";
import {SidebarContext} from "../../Providers/SidebarProvider";

const MainHeader = () => {
    return (
        <SidebarContext.Consumer>{
            ({open, setOpen}) => (<header className={style.main}>
                <div className={style.alignLeft} style={{flex: 1}}></div>
                <div className={style.logo}>
                    <img src={"" + logo} alt="logo"/>
                </div>
                <div style={{flex: 1}}></div>
                <div className={style.mobileHide} style={{alignSelf: "end"}}>
                    <ul className={style.spis}>
                        <li className={style.listItem}>
                            <Link className={style.link}
                                  to="https://chastnaya-shkola.ru/nachalnaya-shkola/">ОБРАЗОВАНИЕ</Link>
                        </li>
                        <li className={style.listItem}>
                            <Link className={style.link} to="https://chastnaya-shkola.ru/postupleniye-oplata/">ПОСТУПЛЕНИЕ
                                И ОПЛАТА</Link>
                        </li>
                        <li className={style.listItem}>
                            <Link className={style.link} to="https://chastnaya-shkola.ru/about/">ИНФОРМАЦИЯ</Link>
                        </li>
                        <li className={style.listItem}>
                            <Link className={style.link} to="https://chastnaya-shkola.ru/contact/">КОНТАКТЫ</Link>
                        </li>
                        <li className={`${style.listItem} ${style.listContactItem}`}>
                            <Link to="tel: +7 921 890-29-35" style={{textDecoration: "none"}}><p>+7 921 890-29-35</p>
                            </Link>
                            <Link className={style.contactLink} to="https://chastnaya-shkola.ru/contact/">Обратная
                                связь</Link>
                        </li>
                    </ul>
                </div>
                <button className={style.menuButton} style={{alignSelf: "center", marginLeft: "45px"}}
                        onClick={() => setOpen(!open)}>=
                </button>
            </header>)
        }</SidebarContext.Consumer>
    );
};

export default MainHeader;