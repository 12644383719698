import {createContext, useContext, useState} from "react";
import {AuthContext} from "./AuthProvider";
import {ApiInstance} from "../api";

export const GroupContext = createContext(null);

export function GroupProvider({children}) {
    const {token, setAuth} = useContext(AuthContext);
    const [content, setContent] = useState(true);
    const [group, setGroup] = useState(null);

    if (token) {
        if (!group && content) {
            ApiInstance(token).get("/group").then(r => {
                let data = r.data;
                if (data.period) {
                    data.teacherFullName = data.period.grade + "-" + data.name;
                    data.fullName = data.period.grade + data.name;
                    data.yearsRangeString = data.period.yearsRange[0] + " - " + data.period.yearsRange[1];
                }
                setGroup(data);
                setContent(true);
            }).catch(r => {
                setContent(false);
                if (r.status === 401) {
                    setAuth(null);
                }
            });
        }
    } else {
        if (group || !content) {
            setGroup(null);
            setContent(true);
        }
    }
    return (<GroupContext.Provider value={[group, content, setGroup]}>
        {children}
    </GroupContext.Provider>);
}

