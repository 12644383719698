import "./style.css";
import React from "react";
import {AuthProvider} from "./Providers/AuthProvider";
import {UserProvider} from "./Providers/UserProvider";
import {SidebarProvider} from "./Providers/SidebarProvider";
import {UserMenu} from "./Components/UserMenu/UserMenu";
import MainHeader from "./Components/MainHeader/MainHeader";
import Breadcrumbs from "./Components/Breadcrumbs/Breadcrumbs";
import {
    createBrowserRouter,
    createRoutesFromElements,
    Navigate,
    Route,
    Router,
    RouterProvider,
    Routes
} from "react-router-dom";
import Login from "./Components/Login/Login";
import EditProfile from "./Components/EditProfile/EditProfile";
import Sidebar from "./Components/Sidebar/Sidebar";
import Footer from "./Components/Footer/Footer";
import {GroupProvider} from "./Providers/GroupProvider";
import {DiaryPage} from "./Pages/Diary/DiaryPage";
import {SchedulePage} from "./Pages/Schedule/SchedulePage";
import QuarterEdit from "./Components/QuarterEdit/QuarterEdit";
import JournalPage from "./Pages/Journal/JournalPage";
import {Registration} from "./Components/Registration/Registration";
import {WrongSite} from "./Pages/WrongSite/WrongSite";

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/*" element={<Main/>}/>
    )
);

function Main() {
    return <div>
        <UserMenu/>
        <MainHeader/>
        <div style={{position: "relative", flex: 1}}>
            <Breadcrumbs/>
            <Routes>
                <Route path="/*" element={<Navigate to="/schedule" replace={true}/>}/>
                <Route path="auth" element={<Login/>}/>
                <Route path="/profile" element={<EditProfile/>}/>
                <Route path="diary/*" element={<DiaryPage/>}/>
                <Route path="schedule/*" element={<SchedulePage/>}/>
                <Route path="/journal/*" element={<JournalPage/>}/>
                <Route path="quarters" element={<QuarterEdit/>}/>
                <Route path="student/register" element={<Registration/>}/>
                <Route path="wrong" element={<WrongSite/>}/>
            </Routes>
            <Sidebar/>
        </div>
        <Footer/>
    </div>;
}

function App() {
    return (
        <AuthProvider>
            <UserProvider>
                <GroupProvider>
                    <SidebarProvider>
                        <RouterProvider router={router}/>
                    </SidebarProvider>
                </GroupProvider>
            </UserProvider>
        </AuthProvider>
    );
}

export default App;

// main color - #618935