import React, {useContext, useState} from 'react';
import style from './LoginStyle.module.css';
import {AuthContext} from "../../Providers/AuthProvider";
import {ApiInstance} from "../../api";
import {Navigate} from "react-router-dom";
import {Field} from "../EditProfile/EditProfile";

export default function Login() {
    const {token, setAuth} = useContext(AuthContext);

    if (token) {
        return <Navigate to="/"/>;
    }
    return <LoginForm setAuth={setAuth}/>;
}

function LoginForm({setAuth}) {
    const [state, setState] = useState({
        email: null,
        password: null,
        remember: false
    });
    const [errorMessages, setErrorMessages] = useState({
        email: "",
        password: ""
    });

    function setValue(e) {
        setState({...state, [e.target.name]: e.target.value});
    }

    function handleSubmit(event) {
        ApiInstance().post("/login", {
            email: state.email,
            password: state.password,
            remember: state.remember
        }).then((response) => {
            setAuth(response.data.auth);
            setErrorMessages({email: "", password: ""});
        }).catch(e => {
            let error = {
                email: e.response.data.errors.email ? e.response.data.errors.email[0] : "",
                password: e.response.data.errors.password ? e.response.data.errors.password[0] : "",
            }
            setErrorMessages(error);
        });
        event.preventDefault();
    }

    return (
        <form onSubmit={handleSubmit}>
            <div className={style.main}>
                <div className={style.auth}>
                    <div className={style.top}>
                        <h2 className={style.title}>Войти в аккаунт</h2>
                        <a className={style.close} href="/">×</a>
                    </div>
                    <Field name="email" defaultValue={state.email} update={setValue} error={errorMessages.email}
                           placeholder="Введите логин (email)" label="Логин"/>
                    <Field name="password" defaultValue={state.password} update={setValue}
                           error={errorMessages.password}
                           placeholder="Введите пароль" label="Пароль" type="password"/>
                    <div className={style.bottom}>
                        <input type="submit" value="Войти →" className={style.button}/>
                        <div style={{alignSelf: "center"}}>
                            <input className={style.checkbox} checked={state.remember}
                                   onChange={(event) => setState({...state, remember: event.target.checked})}
                                   type="checkbox"/>
                            <span className={style.secondary}>запомнить</span>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
}