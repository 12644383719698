import React, {useContext, useState} from 'react';
import style from './QuarterEditStyle.module.css';
import deleteIcon from '../../Images/cancelIcon.png';
import resetIcon from '../../Images/resetIcon.png';
import saveIcon from "../../Images/saveIcon.png";
import cancelChangesIcon from "../../Images/cancelChangesIcon.png";
import {GroupContext} from "../../Providers/GroupProvider";
import {ApiInstance} from "../../api";
import {AuthContext} from "../../Providers/AuthProvider";
import Swal from "sweetalert2";
import {Navigate} from "react-router-dom";

QuarterEdit.periods = [];
QuarterEdit.quarters = [];
QuarterEdit.added = [];
QuarterEdit.deleted = [];
QuarterEdit.qList = [];
QuarterEdit.edited = [false, false, false];
QuarterEdit.chosenPeriodId = null;
QuarterEdit.errors = {};

function sortQuarterLists () {
    QuarterEdit.qList = [];
    for (let j = 1; j <= 4; j ++) {
        for (let i = 0; i < QuarterEdit.deleted.length; i++) {
            if (QuarterEdit.deleted[i].order === j) {
                QuarterEdit.qList.push(QuarterEdit.deleted[i]);
                break;
            }
        }
        for (let i = 0; i < QuarterEdit.quarters.length; i++) {
            if (QuarterEdit.quarters[i].order === j) {
                QuarterEdit.qList.push(QuarterEdit.quarters[i]);
                break;
            }
        }
        for (let i = 0; i < QuarterEdit.added.length; i++) {
            if (QuarterEdit.added[i].order === j) {
                QuarterEdit.qList.push(QuarterEdit.added[i]);
                break;
            }
        }
    }
}

function checkEdited () {
    for (let i = 0; i < 4; ++ i) {
        if (QuarterEdit.edited[i]) return true;
    }
    return false;
}

export default function QuarterEdit() {
    const {token} = useContext(AuthContext);
    const [group] = useContext(GroupContext);
    const [periods, setPeriods] = useState(false);
    const [quarters, setQuarters] = useState(false);
    const [hasUpdates, setHasUpdates] = useState(false);

    if (!token) return <Navigate to="/auth" replace={true}/>;
    if (!group) return;
    if (!periods) loadPeriods();
    if (QuarterEdit.chosenPeriodId === null) QuarterEdit.chosenPeriodId = group.periodId;
    function loadPeriods() {
        if (periods) return;
        ApiInstance(token).get('/periods').then(r => {
            QuarterEdit.periods = r.data;
            setPeriods(true);
        });
    }

    function deleteQuarters () {
        return new Promise((resolve, reject) => {
            let p = [];
            QuarterEdit.deleted.forEach(q => {
                p.push(new Promise((res, rej) => {
                    ApiInstance(token).delete(`/quarters/${q.id}`).then(r => {
                        let h = QuarterEdit.deleted;
                        QuarterEdit.deleted = [];
                        h.forEach(f => f.id !== q.id ? QuarterEdit.deleted.push(f) : "");
                        res(true)
                    }).catch(r => {
                        let string = '';
                        Object.keys(r.response.data.errors).forEach(key => {
                            string = string + "\n" + r.response.data.errors[key][0];
                        });
                        Swal.fire({
                            title: "Ошибка удаления",
                            text: "Четверть " + q.order + ": " + string,
                            icon: "error"
                        });
                        rej(r);
                    })
                }).catch(r => {
                    let string = '';
                    Object.keys(r.response.data.errors).forEach(key => {
                        string = string + "\n" + r.response.data.errors[key][0];
                    });
                    Swal.fire({
                        title: "Ошибка удаления",
                        text: "Четверть " + q.order + ": " + string,
                        icon: "error"
                    });
                }))
            })
            Promise.all(p).then(r => resolve(true)).catch(r => reject(r));
        }).catch();
    }

    function updateQuarters () {
        return new Promise((resolve, reject) => {
            let p = [];
            QuarterEdit.quarters.forEach(q => {
                p.push(new Promise((res, rej) => {
                    ApiInstance(token).post(`/quarters/${q.id}/edit`, {
                        startAt: q.startAt,
                        endAt: q.endAt,
                    }).then(r => res(true)).catch(r => {
                        let string = '';
                        Object.keys(r.response.data.errors).forEach(key => {
                            string = string + "\n" + r.response.data.errors[key][0];
                        });
                        Swal.fire({
                            title: "Ошибка запроса, данные неверные",
                            text: "Четверть " + q.order + ": " + string,
                            icon: "error"
                        });
                        rej(r)
                    });
                }).catch(r => {
                    let string = '';
                    Object.keys(r.response.data.errors).forEach(key => {
                        string = string + "\n" + r.response.data.errors[key][0];
                    });
                    Swal.fire({
                        title: "Ошибка запроса, данные неверные",
                        text: "Четверть " + q.order + ": " + string,
                        icon: "error"
                    });
                }))
            })
            Promise.all(p).then(r => resolve(true)).catch(r => reject(r));
        }).catch(e => console.log(e));
    }

    function addQuarters () {
        return new Promise((resolve, reject) => {
            let p = [];
            QuarterEdit.added.forEach(q => {
                p.push(new Promise((res, rej) => {
                    ApiInstance(token).post(`/quarters/create`, {
                        periodId: QuarterEdit.chosenPeriodId,
                        order: q.order,
                        startAt: q.startAt,
                        endAt: q.endAt,
                    }).then(() => {
                        let h = QuarterEdit.added;
                        QuarterEdit.added = [];
                        h.forEach(f => f.id !== q.id ? QuarterEdit.added.push(f) : "");
                        res(true);
                    }).catch(r => {
                        let string = '';
                        Object.keys(r.response.data.errors).forEach(key => {
                            string = string + "\n" + r.response.data.errors[key][0];
                        });
                        Swal.fire({
                            title: "Ошибка запроса, данные неверные",
                            text: "Четверть " + q.order + ": " + string,
                            icon: "error"
                        });
                        rej(r)
                    })
                }).catch(r => {
                    let string = '';
                    Object.keys(r.response.data.errors).forEach(key => {
                        string = string + "\n" + r.response.data.errors[key][0];
                    });
                    Swal.fire({
                        title: "Ошибка запроса, данные неверные",
                        text: "Четверть " + q.order + ": " + string,
                        icon: "error"
                    });
                }))
            })
            Promise.all(p).then(r => resolve(true)).catch(r => reject(r));
        }).then(r => {
            QuarterEdit.periods = [];
            QuarterEdit.quarters = [];
            QuarterEdit.added = [];
            QuarterEdit.deleted = [];
            QuarterEdit.qList = [];
            // QuarterEdit.edited = [false, false, false];
            QuarterEdit.chosenPeriodId = null;
            setHasUpdates(false);
            setPeriods(false);
            setQuarters(false);
        }).catch(e => console.log(e.response.data.errors));
    }

    function save () {
        deleteQuarters().then(r => {
            updateQuarters().then(r => {
                addQuarters().then(r => {
                    setQuarters(false);
                });
            })
        })
    }

    return (periods) ? (
        <div style={{padding: "15px 5%"}}>
            <div style={{display: "flex"}}>
                <PeriodList token={token} periodId={group.periodId} quarters={quarters} setQuarters={setQuarters} name={group.name} setHasUpdates={setHasUpdates} hasUpdates={hasUpdates}/>
                <QuarterList quarters={quarters} setHasUpdates={setHasUpdates} hasUpdates={hasUpdates}/>
            </div>
            {hasUpdates ? (
                <div style={{display: "flex", justifyContent: "left", marginTop: "30px"}}>
                    <div style={{display: "flex", width: "50%"}}>
                        <button className={style.button} style={{background: "#C0D9A5"}} onClick={save}>
                            <img style={{width: "30px"}} src={saveIcon} alt=""/>
                            <div style={{marginLeft: "15px"}}>Сохранить изменения</div>
                        </button>
                        <button className={style.button}>
                            <img style={{width: "30px"}} src={cancelChangesIcon} alt=""/>
                            <div style={{marginLeft: "15px"}} onClick={() => {
                                QuarterEdit.periods = [];
                                QuarterEdit.quarters = [];
                                QuarterEdit.added = [];
                                QuarterEdit.deleted = [];
                                QuarterEdit.qList = [];
                                QuarterEdit.edited = [false, false, false];
                                QuarterEdit.chosenPeriodId = null;
                                setHasUpdates(false);
                                setPeriods(false);
                                setQuarters(false);
                            }}>Отменить</div>
                        </button>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </div>
    ) : (
        <div className={style.loaderBlock}>
            <div className={style.loader}></div>
        </div>
    );
};

function PeriodList({token, periodId, quarters, setQuarters, name, setHasUpdates, hasUpdates}) {
    const [chosenPeriod, setChosenPeriod] = useState(periodId);

    if (!quarters) loadQuarters();

    function loadQuarters() {
        if (quarters) return;
        ApiInstance(token).get(`/periods/${chosenPeriod}/quarters`).then(r => {
            const data = r.data;
            console.log(QuarterEdit.quarters.length);
            if (QuarterEdit.quarters.length <= 0) {
                data.forEach(d => QuarterEdit.quarters.push({
                    status: 0, // -1 - deleted, 0 - nominal, 1 - added
                    id: d.id,
                    order: d.order,
                    startAt: d.startAt,
                    endAt: d.endAt,
                    errorMessage: "",
                }))
            }
            sortQuarterLists();
            setQuarters(true);
        })
    }

    return (
        <div style={{width: "50%"}}>
            <h1>Информация о классе:</h1>
            <br/>
            <span style={{
                fontSize: "1.3rem",
                marginTop: "15px"
            }}>Нынешний период: <u>Период №{periodId}</u></span>
            <br/>
            <span style={{fontSize: "1.3rem", marginTop: "15px"}}>Литера класса: {name}</span>

            <hr style={{marginTop: '15px', width: "60%"}}/>
        </div>
    );
}

function QuarterList({quarters, hasUpdates, setHasUpdates}) {
    const [updated, setUpdated] = useState(true);

    if (!updated) {
        setUpdated(true);
    }

    return (
        <div style={{width: "50%"}}>
            <h1>Четверти:</h1>
            {(quarters && updated) ? (
                <div style={{padding: "15px 0"}}>
                    <ul className={style.list}>
                        {QuarterEdit.qList.map((q, index) => (q.status === -1) ? (
                            <DeletedQuarter q={q} key={`quarter_item_${index}`} setUpdated={setUpdated} hasUpdates={hasUpdates} setHasUpdates={setHasUpdates}/>
                        ) : (
                            (q.status === 0) ? (
                                <Quarter q={q} key={`quarter_item_${index}`} setUpdated={setUpdated} hasUpdates={hasUpdates} setHasUpdates={setHasUpdates}/>
                            ) : (
                                <AddedQuarter q={q} key={`added_quarter_item_${index}`} index={index} setUpdated={setUpdated} hasUpdates={hasUpdates} setHasUpdates={setHasUpdates}/>
                            )
                        ))}
                    </ul>
                </div>
            ) : (
                <div className={style.loaderBlock}>
                    <div className={style.loader}></div>
                </div>
            )}
            {(QuarterEdit.quarters.length + QuarterEdit.added.length < 4) ? (
                <button className={style.addButton} onClick={() => {
                    let n = 5, h = [false, false, false, false];
                    QuarterEdit.quarters.forEach(d => h[d.order - 1] = true);
                    QuarterEdit.added.forEach(d => h[d.order - 1] = true);

                    for (let i = 0; i < 4; i ++) {
                        if (!h[i]) {
                            n = i + 1;
                            break;
                        }
                    }
                    QuarterEdit.added.push({
                        status: 1,
                        id: QuarterEdit.added.length,
                        order: n,
                        startAt: "2024-01-01 00:00:00",
                        endAt: "2024-01-01 23:59:59",
                        errorMessage: "",
                    })
                    sortQuarterLists();

                    QuarterEdit.edited = [
                        QuarterEdit.edited[0],
                        QuarterEdit.edited[1],
                        true,
                    ]
                    setHasUpdates(true);
                    setUpdated(false);
                }}>Добавить четверть</button>
            ) : (
                <></>
            )}
        </div>
    );
}

function Quarter({q, index, setUpdated, hasUpdates, setHasUpdates}) {
    return (<li style={{marginTop: "5px", width: "40%", padding: "15px 30px"}}>
            <div className={style.listItem}>
                <div style={{display: "flex", alignItems: "center"}}>
                    <span style={{fontSize: "1.5rem", fontWeight: 600, alignSelf: "center"}}>
                        {q.order}-я четверть
                    </span>
                    <button className={style.deleteButton} onClick={() => {
                        QuarterEdit.deleted.push({...q, status: -1});
                        let h = QuarterEdit.quarters;
                        QuarterEdit.quarters = [];
                        h.forEach(f => (f.id !== q.id) ? QuarterEdit.quarters.push(f) : "");
                        sortQuarterLists();
                        setHasUpdates(true);
                        setUpdated(false);
                    }}>
                        <img src={deleteIcon} alt="удалить"/>
                    </button>
                </div>
                <small className={style.errorMessage}>{q.errorMessage}</small>
                <div style={{display: "flex"}}>
                    <input type="date" value={q.startAt.substring(0, 10)} onChange={(e) => {
                        let h = QuarterEdit.quarters;
                        QuarterEdit.quarters = [];
                        h.forEach(f => (f.id !== q.id) ? QuarterEdit.quarters.push(f) : QuarterEdit.quarters.push({
                            ...f,
                            startAt: e.target.value + " 00:00:00"
                        }))
                        sortQuarterLists();
                        QuarterEdit.edited = [
                            QuarterEdit.edited[0],
                            true,
                            QuarterEdit.edited[2],
                        ];
                        setHasUpdates(checkEdited);
                        setUpdated(false);
                    }}/>
                    -
                    <input type="date" value={q.endAt.substring(0, 10)} onChange={(e) => {
                        let h = QuarterEdit.quarters;
                        QuarterEdit.quarters = [];
                        h.forEach(f => (f.id !== q.id) ? QuarterEdit.quarters.push(f) : QuarterEdit.quarters.push({
                            ...f,
                            endAt: e.target.value + " 00:00:00"
                        }))
                        sortQuarterLists();
                        QuarterEdit.edited = [
                            QuarterEdit.edited[0],
                            true,
                            QuarterEdit.edited[2],
                        ];
                        setHasUpdates(checkEdited);
                        setUpdated(false);
                    }}/>
                </div>
            </div>
        </li>
    )
}

function AddedQuarter({q, index, setUpdated, hasUpdates, setHasUpdates}) {
    return (
        <li style={{marginTop: "5px", background: "#84A96150", width: "40%", padding: "15px 30px"}}>
            <div className={style.listItem}>
                <div style={{display: "flex", alignItems: "center"}}>
                    <span style={{fontSize: "1.5rem", fontWeight: 600, alignSelf: "center"}}>
                        {q.order}-я четверть
                    </span>
                    <button className={style.deleteButton} onClick={() => {
                        let h = QuarterEdit.added;
                        QuarterEdit.added = [];
                        h.forEach((f, j) => (f.id !== q.id) ? QuarterEdit.added.push(f) : "");
                        sortQuarterLists();
                        QuarterEdit.edited = [
                            QuarterEdit.edited[0],
                            QuarterEdit.edited[1],
                            QuarterEdit.added.length > 0,
                        ];
                        setHasUpdates(checkEdited);
                        setUpdated(false);
                    }}>
                        <img src={deleteIcon} alt="удалить"/>
                    </button>
                </div>
                <small className={style.errorMessage}>{q.errorMessage}</small>
                <div style={{display: "flex"}}>
                    <input type="date" value={q.startAt.substring(0, 10)} onChange={(e) => {
                        let h = QuarterEdit.added;
                        QuarterEdit.added = [];
                        h.forEach(f => (f.id !== q.id) ? QuarterEdit.added.push(f) : QuarterEdit.added.push({
                            ...f,
                            startAt: e.target.value + " 00:00:00"
                        }))
                        sortQuarterLists();
                        setUpdated(false);
                    }}/>
                    -
                    <input type="date" value={q.endAt.substring(0, 10)} onChange={(e) => {
                        let h = QuarterEdit.added;
                        QuarterEdit.added = [];
                        h.forEach(f => (f.id !== q.id) ? QuarterEdit.added.push(f) : QuarterEdit.added.push({
                            ...f,
                            endAt: e.target.value + " 00:00:00"
                        }))
                        sortQuarterLists();
                        setUpdated(false);
                    }}/>
                </div>
            </div>
        </li>
    );
}

function DeletedQuarter({q, setUpdated, hasUpdates, setHasUpdates}) {
    return (
        <li style={{marginTop: "5px", background: "#FF111150", width: "40%", padding: "15px 30px"}}>
            <div className={style.listItem}>
                <div style={{display: "flex", alignItems: "center"}}>
                    <span style={{fontSize: "1.5rem", fontWeight: 600, alignSelf: "center"}}>
                        {q.order}-я четверть
                    </span>
                    <button className={style.deleteButton} onClick={() => {
                        let h = QuarterEdit.deleted;
                        QuarterEdit.deleted = [];
                        h.forEach(f => (f.id !== q.id) ? QuarterEdit.deleted.push(f) : "");
                        let p = QuarterEdit.added;
                        QuarterEdit.added = [];
                        p.forEach(d => (d.order !== q.order) ? QuarterEdit.added.push(d) : "");
                        QuarterEdit.quarters.push({...q, status: 0});
                        sortQuarterLists();
                        QuarterEdit.edited = [
                            QuarterEdit.added.length > 0,
                            QuarterEdit.edited[1],
                            QuarterEdit.deleted.length > 0,
                        ];
                        setHasUpdates(checkEdited);
                        setUpdated(false);
                    }}>
                        <img src={resetIcon} alt="удалить"/>
                    </button>
                </div>
                <div style={{display: "flex"}}>
                    <input type="date" value={q.startAt.substring(0, 10)} disabled={true}/>
                    -
                    <input type="date" value={q.endAt.substring(0, 10)} disabled={true}/>
                </div>
            </div>
        </li>
    );
}